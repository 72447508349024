import { RoomJoinedPayload } from 'features/room/types';
import { PublishingOptions, RTCClient } from 'utils/webrtc';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { establishJanusConnectionSaga } from 'features/room/sagas/establishJanusConnectionSaga';
import { roomError, roomJoined } from 'features/room/roomSlice';
import { configureSDKStoredStateSaga } from 'features/sdk/configureSDKStoredStateSaga';
import * as Sentry from '@sentry/react';
import { setupE2eeSaga } from 'features/e2ee/sagas/setupE2eeSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { externalStreamStarted } from 'features/streaming/actions';

export function* onRoomJoinConfigSaga(data: RoomJoinedPayload) {
  try {
    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
    if (e2eeEnabled) {
      yield call(setupE2eeSaga, data);
    }

    RTCClient.supressErrors = false;

    yield call(eventBus.sendMessage, 'userJoined', { local: true });

    const publishingOptions: PublishingOptions = yield call(
      RTCClient.publishingFeed.getJoinPublishingOptions
    );

    RTCClient.publishingFeed.broadcasterLimit = data.broadcastersLimit;

    yield call(establishJanusConnectionSaga, data, publishingOptions);

    yield put(roomJoined());

    if (data?.externalStreams?.length) {
      for (const externalStreamAction of data.externalStreams) {
        yield put(externalStreamStarted(externalStreamAction));
      }
    }

    yield call(configureSDKStoredStateSaga, data.users);
  } catch (error) {
    yield call(Sentry.captureException, error);
    yield put(roomError({ global: true }));
  }
}
