import { onBroadcasterLimitReachedSaga } from 'features/room/sagas/onBroadcasterLimitReachedSaga';
import { onScreenshareLimitReachedSaga } from 'features/room/sagas/onScreenshareLimitReachedSaga';
import { onSessionEndsSoonSaga } from 'features/room/sagas/onSessionEndsSoonSaga';
import { onScreensharingStartSaga } from 'features/streaming/sagas/onScreensharingStartSaga';
import { onScreensharingStopSaga } from 'features/streaming/sagas/onScreensharingStopSaga';
import { onExternalStreamingStartSaga } from 'features/streaming/sagas/onExternalStreamingStartSaga';
import { onExternalStreamingStopSaga } from 'features/streaming/sagas/onExternalStreamingStopSaga';
import { takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { initRoomStarted, loginStarted, roomJoined } from 'features/room/roomSlice';
import { joinRoomSaga } from 'features/room/sagas/joinRoomSaga';
import { initRoomSaga } from 'features/room/sagas/initRoomSaga';
import {
  screenshareStarted,
  screenshareStopped,
  externalStreamStarted,
  externalStreamStopped,
  streamingConnectionEstablished,
  streamStarted,
  streamStopped,
} from 'features/streaming/actions';
import { onConnectionEstablishedSaga } from 'features/streaming/sagas/onConnectionEstablishedSaga';
import { onStreamingStartSaga } from 'features/streaming/sagas/onStreamingStartSaga';
import { onStreamingStopSaga } from 'features/streaming/sagas/onStreamingStopSaga';
import {
  endSession,
  languageChanged,
  leaveSession,
  lockRoomRequested,
  prepareRoomJoin,
  roomJoinRequest,
  sessionEnded,
  sessionLeft,
  signalingBroadcasterLimitReached,
  signalingEntryAccessAwaitingStarted,
  signalingScreenshareLimitReached,
  signalingSessionEndsSoon,
  startBroadcastRequest,
  unlockRoomRequested,
} from 'features/room/actions';
import { loginRoomSaga } from 'features/room/sagas/loginRoomSaga';
import { endSessionSaga } from 'features/room/sagas/endSessionSaga';
import { leaveSessionSaga } from 'features/room/sagas/leaveSessionSaga';
import { terminateSessionSaga } from 'features/application/sagas/terminateSessionSaga';
import { startRoomBroadcastSaga } from 'features/room/sagas/startRoomBroadcastSaga';
import { changeLanguageSaga } from 'features/room/sagas/changeLanguageSaga';
import { prepareRoomJoinSaga } from 'features/room/sagas/prepareRoomJoinSaga';
import { onRoomJoinedSaga } from 'features/room/sagas/onRoomJoinedSaga';
import { lockRoomSaga } from 'features/room/sagas/lockRoomSaga';
import { unlockRoomSaga } from 'features/room/sagas/unlockRoomSaga';
import {
  admitEntry,
  bulkAdmitEntry,
  bulkRejectEntry,
  rejectEntry,
  signalingBulkAccessRejected,
} from 'features/users/actions';
import { rejectEntrySaga } from 'features/room/sagas/rejectEntrySaga';
import { admitEntrySaga } from 'features/room/sagas/admitEntrySaga';
import { onBulkEntryRejectedSaga } from 'features/room/sagas/onBulkEntryRejectedSaga';
import { bulkAdmitEntrySaga } from 'features/room/sagas/bulkAdmitEntrySaga';
import { bulkRejectEntrySaga } from 'features/room/sagas/bulkRejectEntrySaga';
import { onEntryAccessAwaitingStarted } from 'features/room/sagas/onEntryAccessAwaitingStarted';

export default function* roomWatcher() {
  yield takeLatest(languageChanged, changeLanguageSaga);

  yield takeLatest(initRoomStarted, initRoomSaga);

  yield takeLeading(roomJoinRequest, joinRoomSaga);
  yield takeLatest(prepareRoomJoin, prepareRoomJoinSaga);

  yield takeEvery(roomJoined, onRoomJoinedSaga);

  yield takeLatest(startBroadcastRequest, startRoomBroadcastSaga);
  yield takeLatest(streamingConnectionEstablished, onConnectionEstablishedSaga);
  yield takeLatest(streamStarted, onStreamingStartSaga);
  yield takeLatest(streamStopped, onStreamingStopSaga);
  yield takeLatest(screenshareStarted, onScreensharingStartSaga);
  yield takeLatest(screenshareStopped, onScreensharingStopSaga);
  yield takeLatest(externalStreamStarted, onExternalStreamingStartSaga);
  yield takeLatest(externalStreamStopped, onExternalStreamingStopSaga);
  yield takeLatest(loginStarted, loginRoomSaga);

  // end session
  yield takeLatest(endSession, endSessionSaga);
  yield takeLatest(leaveSession, leaveSessionSaga);
  yield takeLatest(sessionEnded, terminateSessionSaga);
  yield takeLatest(sessionLeft, terminateSessionSaga);

  // room entry control
  yield takeEvery(lockRoomRequested, lockRoomSaga);
  yield takeEvery(unlockRoomRequested, unlockRoomSaga);
  yield takeEvery(signalingEntryAccessAwaitingStarted, onEntryAccessAwaitingStarted);
  yield takeEvery(admitEntry, admitEntrySaga);
  yield takeEvery(rejectEntry, rejectEntrySaga);
  yield takeEvery(bulkAdmitEntry, bulkAdmitEntrySaga);
  yield takeEvery(bulkRejectEntry, bulkRejectEntrySaga);
  yield takeEvery(signalingBulkAccessRejected, onBulkEntryRejectedSaga);
  yield takeEvery(signalingBroadcasterLimitReached, onBroadcasterLimitReachedSaga);
  yield takeEvery(signalingScreenshareLimitReached, onScreenshareLimitReachedSaga);
  yield takeEvery(signalingSessionEndsSoon, onSessionEndsSoonSaga);
}
