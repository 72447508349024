import { selectShowGridPanelTabs } from 'features/layout/features/content/contentSlice';
import { useAppSelector } from 'store/hooks';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const GridPanelContainer = ({
  children,
  id,
  sx,
}: {
  children: React.ReactNode | React.ReactChild;
  id: string;
  sx?: SxProps;
}) => {
  const showTabs = useAppSelector(selectShowGridPanelTabs);

  return (
    <Box
      id={id}
      sx={[
        {
          background: (theme) => theme.palette.grey['50'],
          position: 'absolute',
          borderRadius: showTabs ? '0 0 8px 8px' : '8px',
          overflow: 'hidden',
          display: 'flex',
          width: '100%',
          height: '100%',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
