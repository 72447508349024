import { createAction } from '@reduxjs/toolkit';
import { SignalingEventPayload } from 'services/signaling';
import { SignalingWhiteboardPayload } from 'features/layout/features/content/types';
import { RejectedWhiteboardData } from './types';

export const fileUploadPrepared = createAction('whiteboard/fileUploadPrepared');
export const signalingWhiteboardDataReceived = createAction<
  SignalingEventPayload<SignalingWhiteboardPayload>
>('signaling/whiteboardDataReceived');
export const signalingWhiteboardDataRequestRejected = createAction<RejectedWhiteboardData>(
  'signaling/whiteboardDataRequestRejected'
);
export const requestWhiteboardData = createAction<string>('requestWhiteboardData');
