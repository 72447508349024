import { TLRecord } from '@digitalsamba/tldraw';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { decryptWBRecords } from 'utils/whiteboard/decryptWBRecords';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { parseBoardState } from 'utils/whiteboard/parseBoardState';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { selectRoomId } from 'features/room/roomSlice';

export function* parseWhiteboardStateSaga(id: string, shapes: string[]) {
  let parsedBoardState: TLRecord[] = [];

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const roomId: string = yield select(selectRoomId);

    const whiteboardKey: AesCm256EncryptionKey | undefined = yield call(
      E2EEManager.whiteboard.getKey,
      id,
      roomId
    );

    const decryptionKey = whiteboardKey?.encryptionKey;
    if (!decryptionKey) {
      throw new Error(`Unable to retrieve decryption key for whiteboard ID: ${id}`);
    }

    try {
      parsedBoardState = yield call(decryptWBRecords, shapes, decryptionKey);
    } catch (error) {
      const errorMessage = `Cannot decrypt whiteboard state for id=${id}, roomId=${roomId}`;

      Sentry.captureException(error);
      logger.remote({ system: true, capture: 'e2ee' }).error(errorMessage);
    }
  } else {
    parsedBoardState = yield call(parseBoardState, shapes);
  }

  return parsedBoardState;
}
