import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GalleryPagination } from 'features/content-library/components/content-types/GalleryFile/GalleryPagination';
import { ToggleThumbnailsControl } from 'features/content-library/components/content-types/GalleryFile/ToggleThumbnailsControl';
import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Keyboard, Mousewheel, Pagination, Virtual } from 'swiper/modules';
import { breakpointValues } from 'theme/themeBaseOptions';
import { contentLibraryChangeFilePageRequested } from 'features/content-library/actions';
import { ContentLibraryMultiplePageOpenedFile } from 'features/content-library/types';

import {
  selectContentLibraryOpenedFileCurrentPage,
  selectContentLibraryOpenedFileThumbnailsActive,
} from 'features/content-library/contentLibrarySlice';
import { Slide, SlideImg } from 'features/content-library/components/content-types/styled';
import { useBreakpoints } from 'hooks/useBreakpoints';
import ThumbnailList from 'features/content-library/components/content-types/GalleryFile/thumbnails/ThumbnailList';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/virtual';

const StyledContainer = styled(Box)<{ height?: number }>(({ height }) => ({
  '.swiper': {
    width: '100%',
    height: '100%',
  },
  ...(height && {
    '.swiper-wrapper': {
      maxHeight: `${height}px`,
    },
  }),
}));

const handleKeyboard = (slider: SwiperClass, keyCode: string) => {
  switch (keyCode) {
    case '37':
      slider.slidePrev();
      break;
    case '39':
      slider.slideNext();
      break;
    default:
      break;
  }
};

export type GalleryFileProps = {
  file: ContentLibraryMultiplePageOpenedFile;
  size: { width?: number; height?: number };
};

const SwiperLazyPreloader = styled('div')(({ theme }) => ({
  '--swiper-preloader-color': theme.palette.primary.main,
}));

const swiperConfig = {
  keyboard: {
    enabled: true,
  },
  modules: [Keyboard, Mousewheel, Pagination, Virtual],
};

const GalleryFile = ({ file, size }: GalleryFileProps) => {
  const dispatch = useAppDispatch();

  const swiperRef = useRef<SwiperClass>();
  const activeIndexRef = useRef(0);
  const ignoreUpdatesRef = useRef(false);

  const currentPage = useAppSelector(selectContentLibraryOpenedFileCurrentPage);
  const thumbnailsActive = useAppSelector(selectContentLibraryOpenedFileThumbnailsActive);

  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (currentPage !== activeIndexRef.current) {
      if (swiperRef.current) {
        activeIndexRef.current = currentPage;

        ignoreUpdatesRef.current = true;
        swiperRef.current.slideTo(currentPage);
      }
    }
  }, [currentPage]);

  const goToPage = useCallback((idx: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(idx);
    }
  }, []);

  const handleActiveSlideChange = (e: SwiperClass) => {
    if (ignoreUpdatesRef.current) {
      ignoreUpdatesRef.current = false;
    } else {
      activeIndexRef.current = e.activeIndex;

      dispatch(
        contentLibraryChangeFilePageRequested({
          id: file.id,
          page: e.activeIndex,
        })
      );
    }
  };

  const showThumbs = !isMobile && size?.width && size.width > breakpointValues.md;

  return (
    <StyledContainer
      height={size.height}
      id="slide-container"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
      }}
    >
      {showThumbs && thumbnailsActive ? (
        <ThumbnailList pages={file.pages} goToPage={goToPage} currentPage={currentPage} />
      ) : null}
      <div
        style={{
          flexGrow: '1',
          position: 'relative',
          pointerEvents: 'all',
        }}
      >
        {showThumbs ? <ToggleThumbnailsControl /> : null}
        <GalleryPagination goToPage={goToPage} />
        <Swiper
          direction="vertical"
          spaceBetween={10}
          slidesPerView={1}
          mousewheel
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onKeyPress={handleKeyboard}
          onSlideChange={handleActiveSlideChange}
          initialSlide={currentPage}
          key="gallery-present"
          {...swiperConfig}
          virtual
        >
          {file.pages.map((img, idx) => (
            <SwiperSlide key={idx} virtualIndex={idx}>
              <Slide sx={{ height: 'calc(100% - 12px)' }}>
                <SlideImg src={img.url} loading="lazy" />
                <SwiperLazyPreloader className="swiper-lazy-preloader" />
              </Slide>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </StyledContainer>
  );
};

export default GalleryFile;
