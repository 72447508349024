import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useWhiteboardControl } from 'features/toolbar/Toolbar/controls/whiteboard/useWhiteboardControl';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { selectContentLibraryActiveWhiteboards } from 'features/content-library/contentLibrarySlice';
import { whiteboardViewed } from 'features/content-library/actions';
import { useAppSelector } from 'store/hooks';
import { dispatch } from 'store/store';
import Icon from 'components/Icon';

const WhiteboardControl = () => {
  const { t } = useTranslation('room');

  const whiteboards = useAppSelector(selectContentLibraryActiveWhiteboards);

  const { active, label, createWhiteboard, isFeatureAvailable } = useWhiteboardControl();

  const selectWhiteboard = (index: number) => {
    dispatch(whiteboardViewed(whiteboards[index].id));
  };

  if (!isFeatureAvailable) {
    return null;
  }

  return (
    <ToolbarMenuItem
      menu
      id="whiteboard-control"
      overlay={label}
      ariaLabel={label}
      active={active}
      icon="whiteboard"
      MenuProps={{ PopperProps: { sx: { zIndex: 1600 } } }}
    >
      <MenuItem onClick={createWhiteboard}>
        <ListItemIcon>
          <Icon name="whiteboard-create" />
        </ListItemIcon>
        <ListItemText>{t('toolbar.create_whiteboard')}</ListItemText>
      </MenuItem>
      <Divider hidden={whiteboards.length === 0} />
      <Box sx={{ maxHeight: 'calc(min(200px, 30vh))', overflow: 'auto', maxWidth: '100%' }}>
        {whiteboards.map((item, index) => (
          <MenuItem key={item.id} onClick={() => selectWhiteboard(index)}>
            <ListItemIcon>
              <Icon name="whiteboard-create" />
            </ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </Box>
    </ToolbarMenuItem>
  );
};

export default WhiteboardControl;
