import { v4 as uuidv4 } from 'uuid';
import { selectCaptionsSpokenLanguage } from 'features/transcripts/slices/captions/captionsSlice';
import {
  ExternalStreamingStarted,
  ExternalStreamingStopped,
  StreamingStarted,
  StreamingStopped,
} from 'features/streaming/types';
import { SignalingUserLeftPayload, UserId } from 'features/users/types';
import { store } from 'store/store';
import {
  CloseCodes,
  eventMap,
  roomOnlyEvents,
  RoomOnlyEvents,
  SignalingReceivedMessage,
  SignalingRoomUser,
  SignalingSendMessage,
} from 'services/signaling';
import { logger, logSignalingEvent } from 'utils/logger';
import {
  selectDeviceInfo,
  selectDeviceType,
  selectSignalingInitData,
  signalingReconnectionStarted,
} from 'features/application/applicationSlice';
import { roomError, selectRoomId } from 'features/room/roomSlice';
import i18n from 'i18n';
import { retryDelay } from 'services/signaling/retryDelay';
import { isError } from 'utils/types';
import { getBrowserId } from 'utils/getBrowserId';
import * as Sentry from '@sentry/react';
import { signalingReconnected } from 'features/application/actions';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { stopRecorder } from 'features/recorder/actions';
import { prepareRoomJoin, signalingRoomJoined } from 'features/room/actions';
import { E2EEManager, e2eeNameSensitiveEvents } from 'features/e2ee/E2EEManager';
import { RoomJoinedPayload } from 'features/room/types';

type PendingMessage = {
  joinAction?: ['userJoined', SignalingRoomUser] | ['userLeft', SignalingUserLeftPayload];
  streamActions: (['streamStarted', StreamingStarted] | ['streamStopped', StreamingStopped])[];
  screenshareActions: (
    | ['screenshareStarted', StreamingStarted]
    | ['screenshareStopped', StreamingStopped]
  )[];
};

class SignalingSocket {
  ws: WebSocket | null = null;

  retries: number = 15;

  retryAttempt: number = 0;

  retryTimeout?: number;

  pingInterval?: number;

  connectionFailure: boolean = false;

  isConnectionEstablished: boolean = false;

  isRoomJoined: boolean = false;

  isReconnecting: boolean = false;

  pendingUpdates: Record<UserId, PendingMessage> = {};

  pendingExternalStreamsUpdates: (
    | ['externalStreamStarted', ExternalStreamingStarted]
    | ['externalStreamStopped', ExternalStreamingStopped]
  )[] = [];

  totalInterceptedUpdates = 0;

  roomJoinData?: RoomJoinedPayload;

  pendingRequests: Record<
    string,
    {
      resolve: (value: any) => void;
      reject: (value: any) => void;
    }
  > = {};

  connect = (url: string, token: string, uniqueId: string) => {
    const paramsStr = this.getConnectionParams(token, uniqueId);

    this.ws = new WebSocket(`${url}?${paramsStr}`);

    this.ws.onopen = this.onOpen;
    this.ws.onmessage = this.onMessage;
    this.ws.onclose = this.onClose;

    this.ws.onerror = (event) => {
      logger.remote().error('Signaling socket error', event);
      if (!this.isReconnecting) {
        Sentry.captureException(new Error('Signaling socket error'), {
          contexts: {
            socket: {
              // @ts-ignore
              url: event.target?.url,
              readyState: this.ws?.readyState,
              reconnecting: this.isReconnecting,
              retryAttempt: this.retryAttempt,
              eventType: event.type,
            },
          },
        });
      }

      this.isReconnecting = false;
      if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
        this.ws.close();
      }
    };
  };

  close = (code?: number, reason?: string) => {
    if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
      this.ws.close(code || 1000, reason);
    }
  };

  send = (message: SignalingSendMessage) => {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    }
  };

  sendAsync = <T = unknown>(message: SignalingSendMessage) => {
    const requestId = uuidv4();

    message.requestId = requestId;

    return new Promise<T>((resolve, reject) => {
      this.pendingRequests[requestId] = { resolve, reject };
      this.send(message);
    });
  };

  resetState = () => {
    this.ws = null;
    this.retries = 15;
    this.retryAttempt = 0;
    this.retryTimeout = undefined;
    this.pingInterval = undefined;
    this.connectionFailure = false;
    this.isConnectionEstablished = false;
    this.isReconnecting = false;
  };

  saveMessage = (kind: RoomOnlyEvents, data: any) => {
    logger.warn(
      'SignalingSocket, intercepted user update prior to joining room, userId: ',
      data.id,
      'action: ',
      kind
    );

    this.totalInterceptedUpdates += 1; // slightly off, we don't care

    this.pendingUpdates[data.id] ??= {
      screenshareActions: [],
      streamActions: [],
    };

    if (kind === 'userJoined' || kind === 'userLeft') {
      // join events are mutually exclusive, so we just keep
      // the last one and will handle it upon roomJoined
      this.pendingUpdates[data.id].joinAction = [kind, data];
    }

    // as for stream/screenshare events we store them historically
    // and reconcile the valid final state upon roomJoined
    if (kind === 'streamStarted' || kind === 'streamStopped') {
      this.pendingUpdates[data.id].streamActions.push([kind, data]);
    }

    if (kind === 'screenshareStarted' || kind === 'screenshareStopped') {
      this.pendingUpdates[data.id].screenshareActions.push([kind, data]);
    }

    if (kind === 'externalStreamStarted' || kind === 'externalStreamStopped') {
      this.pendingExternalStreamsUpdates.push([kind, data]);
    }
  };

  preparePendingMessages = (roomJoinedPayload: RoomJoinedPayload) => {
    const updates = Object.entries(this.pendingUpdates);

    logger
      .remote()
      .log(
        `SignalingSocket, processing intercepted updates for ${updates.length} users, ${this.totalInterceptedUpdates} messages were intercepted`
      );

    const { users } = roomJoinedPayload;

    const userList: Record<UserId, SignalingRoomUser> = {};
    for (const user of users) {
      userList[user.id] = user;
    }

    updates.forEach(([userId, userUpdate]) => {
      // handle users's joined state first
      if (userUpdate.joinAction) {
        const [joinActionKind, joinActionPayload] = userUpdate.joinAction;

        if (joinActionKind === 'userLeft') {
          // recentest update from signaling was that user has left.
          // we don't care for streams in this case, just drop the user from
          // roomJoined users list like they were never there

          if (userList[userId]) {
            delete userList[userId];
          }

          return;
        }

        if (!userList[userId]) {
          // if update was that user joined and user is not included in roomJoined, add them.
          userList[userId] = joinActionPayload;
        }
      }

      if (!userList[userId] && !userUpdate.joinAction) {
        // no join action neither roomJoined data. streams seem to be invalid
        logger
          .remote()
          .warn(
            'SignalingSocket, broken state when reconciling: have updates for user that didn’t send neither roomJoined nor userJoined, userId',
            userId,
            'stream actions: ',
            userUpdate.streamActions.length,
            'screenshare actions: ',
            userUpdate.screenshareActions.length
          );
        return;
      }

      // if we've made it to here, everything checks out and we can rely on having user in roomJoined collection.
      // so we've got to update their stream state
      userList[userId].streams ??= [];

      for (const [actionKind, actionData] of userUpdate.streamActions) {
        if (actionKind === 'streamStarted') {
          for (const stream of actionData.streams) {
            if (
              !userList[userId].streams.some(
                (lookupStream) =>
                  lookupStream.mid === stream.mid && lookupStream.type === stream.type
              )
            ) {
              userList[userId].streams.push(stream);
            }
          }
        } else {
          // we've intercepted streamStopped, kill (if found) that mid in roomJoined
          userList[userId].streams = userList[userId].streams.filter(
            (stream) => stream.mid !== actionData.mid
          );
        }
      }

      // if no screenshare changes intercepted, do nothing;
      if (!userUpdate.screenshareActions.length) {
        return;
      }

      let screenshareStreams = userList[userId].screenshareStreams || [];

      for (const [actionKind, actionData] of userUpdate.screenshareActions) {
        if (actionKind === 'screenshareStarted') {
          // update user's screenshare feed id with the most recent one.
          // theoretically this will never change but still feels right;
          userList[userId].screenshareFeedId = actionData.feedId;
          // check if that stream is included in roomJoined payload
          // if not, add it
          for (const stream of actionData.streams) {
            if (
              !screenshareStreams.some(
                (lookupStream) =>
                  lookupStream.mid === stream.mid && lookupStream.type === stream.type
              )
            ) {
              screenshareStreams.push(stream);
            }
          }
        } else {
          // delete stopped stream
          screenshareStreams = screenshareStreams.filter((stream) => stream.mid !== actionData.mid);
        }
      }

      // update associated screensharing vars;

      userList[userId].screensharing = !!screenshareStreams.length;
      userList[userId].startScreenshareTimestamp = Number(new Date()); // =/
      userList[userId].screenshareStreams = screenshareStreams;
    });

    let externalStreams: ExternalStreamingStarted[] = [];

    for (const [actionKind, actionData] of this.pendingExternalStreamsUpdates) {
      if (actionKind === 'externalStreamStarted') {
        externalStreams.push(actionData);
      } else if (actionKind === 'externalStreamStopped') {
        externalStreams = externalStreams.filter((stream) => stream.feedId !== actionData.feedId);
      }
    }

    this.pendingUpdates = {};
    this.pendingExternalStreamsUpdates = [];

    const newUserList = Object.values(userList).filter(Boolean);

    // override initial users state with corrected one;
    return { ...roomJoinedPayload, users: newUserList, externalStreams };
  };

  joinRoom = () => {
    this.isRoomJoined = true;

    const updatedJoinPayload = this.preparePendingMessages(this.roomJoinData!);
    store.dispatch(signalingRoomJoined(updatedJoinPayload));

    this.roomJoinData = undefined;
  };

  private reconnect() {
    const initData = selectSignalingInitData(store.getState());
    const browserId = getBrowserId();

    this.connect(initData.url, initData.token, browserId);
  }

  private ping() {
    const timeout = 25 * 1000;

    if (this.pingInterval) {
      window.clearInterval(this.pingInterval);
    }

    this.pingInterval = window.setInterval(() => {
      this.send({ event: 'ping' });
    }, timeout);
  }

  private shouldRetry = (e: CloseEvent): boolean => {
    const isRecorder = selectIsRecorderSession(store.getState());

    if (isRecorder) {
      store.dispatch(stopRecorder());
      return false;
    }

    if (e.code === CloseCodes.SYSTEM_KICK) {
      store.dispatch(
        roomError({
          global: true,
          name: 'socket-kick',
          title: i18n.t('waiting-screens:room.room_kick.title'),
          message: i18n.t('waiting-screens:room.room_kick.text'),
        })
      );

      return false;
    }

    if (e.code === CloseCodes.NO_RECONNECT || e.code === CloseCodes.TERMINATED) {
      return false;
    }

    if (
      e.code === CloseCodes.INTERNAL_ERROR ||
      e.reason === 'invalid_unique_id' ||
      e.reason === 'invalid_token'
    ) {
      this.connectionFailure = true;
      return false;
    }

    if (this.retryAttempt <= this.retries) {
      return true;
    }

    this.connectionFailure = true;

    return false;
  };

  private onMessage = (e: MessageEvent) => {
    try {
      const message: SignalingReceivedMessage = JSON.parse(e.data);
      const messageStr = `Received signaling message, event: ${message.event}`;

      logSignalingEvent(messageStr, message);

      Sentry.addBreadcrumb({
        category: 'streaming',
        message: messageStr,
        data: message,
      });

      const action = eventMap[message.event];

      if (message.requestId) {
        const resolvers = this.pendingRequests[message.requestId];

        if (resolvers) {
          if (message.success) {
            resolvers.resolve(message.data);
          } else {
            resolvers.reject(message.data);
          }
        }

        store.dispatch(action(message.data));

        return;
      }

      if (!action) {
        return;
      }

      if (!this.isRoomJoined && roomOnlyEvents.includes(message.event)) {
        this.saveMessage(message.event as RoomOnlyEvents, message.data);

        return;
      }

      if (message.event === 'roomJoined') {
        this.roomJoinData = message.data;

        store.dispatch(
          prepareRoomJoin({
            roomId: message.data.roomId,
            roomPin: message.data.roomPin,
            mountpointId: message.data.mountpointId,
          })
        );

        return;
      }

      if (E2EEManager.e2eeEnabled) {
        if (this.isRoomJoined && e2eeNameSensitiveEvents.has(message.event)) {
          const roomId = selectRoomId(store.getState());

          const queued = E2EEManager.enqueuePendingAction(action(message.data), roomId);
          if (queued) {
            return;
          }
        }
      }

      store.dispatch(action(message.data));
    } catch (error) {
      if (isError(error)) {
        Sentry.captureException(error);
        logger.remote().error('SignalingSocket', error.message);
      }
    }
  };

  private onOpen = () => {
    logger.remote().log('Signaling socket: Connection established');

    this.isConnectionEstablished = true;
    this.retryAttempt = 0;

    if (this.isReconnecting) {
      this.isReconnecting = false;
      store.dispatch(signalingReconnected());
    }

    this.ping();
  };

  private onClose = (e: CloseEvent) => {
    logger.remote().log('Signaling socket: Connection died', e);
    this.ws = null;

    if (this.retryTimeout) {
      window.clearTimeout(this.retryTimeout);
    }

    if (this.shouldRetry(e)) {
      if (this.isConnectionEstablished && !this.isReconnecting) {
        this.isReconnecting = true;
        store.dispatch(signalingReconnectionStarted());
      }

      this.retryAttempt += 1;
      const delay = retryDelay(this.retryAttempt);

      logger.remote().log('Signaling socket: attempt to reconnect:', this.retryAttempt);
      logger.log('Signaling socket: delay', delay);

      this.retryTimeout = window.setTimeout(() => {
        this.reconnect();
      }, delay);
    } else {
      this.isConnectionEstablished = false;
      this.isReconnecting = false;

      store.dispatch(eventMap.disconnect({ error: this.connectionFailure }));
    }
  };

  private getConnectionParams = (token: string, uniqueId: string) => {
    const browserDetails = selectDeviceInfo(store.getState());
    const deviceType = selectDeviceType(store.getState());
    const spokenLanguage = selectCaptionsSpokenLanguage(store.getState());

    const params = new URLSearchParams({
      token,
      uniqueId,
      browserName: browserDetails.browser.name || '',
      browserVersion: browserDetails.browser.version || '',
      deviceType,
      os: browserDetails.os.name || '',
      osVersion: browserDetails.os.version || '',
      spokenLanguage,
    });

    return params.toString();
  };
}

export default SignalingSocket;
