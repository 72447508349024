import React from 'react';
import Tooltip from 'components/Tooltip';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { usePushContentControl } from './usePushContentControl';

export const GridPanelPushControl = () => {
  const { controlEnabled, handleClick, label, tooltip } = usePushContentControl();

  if (!controlEnabled) {
    return null;
  }

  return (
    <Tooltip disableInteractive autoFocus title={tooltip}>
      <GridPanelControl aria-label={label} icon="box-arrow" onClick={handleClick} />
    </Tooltip>
  );
};
