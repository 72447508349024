import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { useAppDispatch } from 'store/hooks';

export const useExternalStreamPanelCloseControl = () => {
  const dispatch = useAppDispatch();

  const controlEnabled = false;

  const handleClick = () => {
    dispatch(gridPanelVoided('external'));
  };

  return { controlEnabled, handleClick };
};
