import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import {
  CustomControlConfig,
  HoverParent,
  LayoutConfigState,
  ShowHoverChildrenChangedPayload,
} from 'features/layout/features/config/types';
import { LayoutMode, SourceKind } from 'features/layout/types';
import { SDKConnected } from 'features/sdk/sdkStateSlice';
import { RootState } from 'store/store';
import { authorizeUser } from '../../../room/thunks/authorizeUser';

export const initialState: LayoutConfigState = {
  currentMode: LayoutMode.tiled,
  recordingMode: LayoutMode.tiled,
  minimizeLocal: false,
  showScreensharePreview: false,
  modeControlEnabled: false,
  minimizeOwnTileOnJoinEnabled: false,
  participantNamesInRecordingsEnabled: false,
  hideTilesInRecordingsEnabled: false,
  captionsInRecordingsEnabled: false,
  showHoverChildren: {
    gridPanel: true,
    tiles: true,
  },
  zenMode: true,
  customControls: {
    all: {},
    local: {},
    remote: {},
    'screenshare-local': {},
    'screenshare-remote': {},
    external: {},
  },
  customControlScopes: {},
  broadcasterTileBehaviour: 'all',
};

const layoutConfigSlice = createSlice({
  name: 'layoutConfig',
  initialState,
  reducers: {
    screensharePreviewToggled(state) {
      state.showScreensharePreview = !state.showScreensharePreview;
    },
    localStreamExpanded(state) {
      state.minimizeLocal = false;
    },
    localStreamCollapsed(state) {
      state.minimizeLocal = true;
    },
    layoutModeChanged(state, action: PayloadAction<LayoutMode>) {
      state.currentMode = action.payload;

      if (state.currentMode === LayoutMode.auto) {
        state.hideLayoutControls = true;
      } else {
        state.hideLayoutControls = undefined;
      }
    },
    zenModeChanged: {
      reducer(state, action: PayloadAction<boolean>) {
        state.zenMode = action.payload;

        state.showHoverChildren.tiles = !state.zenMode;
        state.showHoverChildren.gridPanel = !state.zenMode;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    showHoverChildrenChanged(state, action: PayloadAction<ShowHoverChildrenChangedPayload>) {
      if (state.zenMode) {
        state.showHoverChildren[action.payload.area] = action.payload.show;
      } else {
        state.showHoverChildren[action.payload.area] = true;
      }
    },
    customControlAdded(state, action: PayloadAction<CustomControlConfig>) {
      state.customControls[action.payload.properties.scope][action.payload.name] = action.payload;
      state.customControlScopes[action.payload.name] = action.payload.properties.scope;
    },
    customControlRemoved(state, action: PayloadAction<string>) {
      const scope = state.customControlScopes[action.payload];

      if (scope) {
        delete state.customControls[scope][action.payload];
        delete state.customControlScopes[action.payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authorizeUser.fulfilled, (state, action) => {
      state.modeControlEnabled = action.payload.settings.layoutModeSwitchEnabled;
      state.recordingMode = action.payload.settings.recordingsLayoutMode;
      state.minimizeOwnTileOnJoinEnabled = action.payload.settings.minimizeOwnTileOnJoinEnabled;
      state.captionsInRecordingsEnabled = action.payload.settings.captionsInRecordingsEnabled;
      state.participantNamesInRecordingsEnabled =
        action.payload.settings.participantNamesInRecordingsEnabled;
      state.hideTilesInRecordingsEnabled = action.payload.settings.hideTilesInRecordingsEnabled;
      state.broadcasterTileBehaviour = action.payload.settings.broadcasterTileVisibility;
    });

    builder.addCase(SDKConnected, (state, action) => {
      const options = action.payload;
      if (options.layoutMode !== undefined) {
        state.currentMode = options.layoutMode;

        if (state.currentMode === LayoutMode.auto) {
          state.hideLayoutControls = true;
        } else {
          state.hideLayoutControls = undefined;
        }
      }
    });
  },
});

export const {
  screensharePreviewToggled,
  localStreamExpanded,
  localStreamCollapsed,
  layoutModeChanged,
  zenModeChanged,
  showHoverChildrenChanged,
  customControlAdded,
  customControlRemoved,
} = layoutConfigSlice.actions;

export const selectCurrentLayoutMode = (state: RootState) => state.layout.config.currentMode;
export const selectCurrentRecordingMode = (state: RootState) => state.layout.config.recordingMode;
export const selectShowCaptionsInRecordings = (state: RootState) =>
  state.layout.config.captionsInRecordingsEnabled;
export const selectShowScrensharePreview = (state: RootState) =>
  state.layout.config.showScreensharePreview;
export const selectLocalStreamMinimized = (state: RootState) => state.layout.config.minimizeLocal;

export const selectHideLayoutControls = (state: RootState) =>
  state.layout.config.hideLayoutControls;

export const selectLayoutModeControlEnabled = (state: RootState) =>
  state.layout.config.modeControlEnabled;

export const selectMinimizeLocalOnJoin = (state: RootState) =>
  state.layout.config.minimizeOwnTileOnJoinEnabled;

export const selectShowParticipantNamesInRecording = (state: RootState) =>
  state.layout.config.participantNamesInRecordingsEnabled;

export const selectZenModeEnabled = (state: RootState) => state.layout.config.zenMode;

export const selectShowHoverChildren = (state: RootState, area: HoverParent) =>
  state.layout.config.showHoverChildren[area];

export const selectHideTilesInRecordings = (state: RootState) =>
  state.layout.config.hideTilesInRecordingsEnabled;

export const selectCustomControls = createSelector(
  [
    (state: RootState) => state.layout.config.customControls,
    (_: RootState, scope: SourceKind) => scope,
  ],
  (customControls, scope) => {
    if (scope === 'debug') {
      return {};
    }

    const sharedControls = customControls.all;

    return {
      ...sharedControls,
      ...customControls[scope],
    };
  }
);

export const selectBroadcasterTileVisibility = (state: RootState) =>
  state.layout.config.broadcasterTileBehaviour;

export default layoutConfigSlice.reducer;
