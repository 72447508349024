import { call, select } from 'redux-saga/effects';
import { selectGridPanel } from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { openLastActiveFileSaga } from 'features/content-library/sagas/openLastActiveFileSaga';

export function* onContentLibraryOpenedFilesReceived() {
  const currentGridPanel: GridPanelName = yield select(selectGridPanel);

  if (currentGridPanel === 'contentLibrary') {
    yield call(openLastActiveFileSaga);
  }
}
