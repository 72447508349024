import { call, select } from 'redux-saga/effects';
import { selectGridPanel } from 'features/layout/features/content/contentSlice';
import { openLastActiveWhiteboardSaga } from 'features/content-library/sagas/openLastActiveWhiteboardSaga';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { openLastActiveFileSaga } from 'features/content-library/sagas/openLastActiveFileSaga';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { GridPanelName } from 'features/layout/features/content/types';
import { openLastActiveScreenshareSaga } from 'features/streaming/sagas/openLastActiveScreenshareSaga';

export function* onGridPanelVoidedSaga() {
  const currentOpenedPanel: GridPanelName = yield select(selectGridPanel);

  if (currentOpenedPanel === 'contentLibrary' || currentOpenedPanel === 'whiteboard') {
    const openedFile: ContentLibraryOpenedFile = yield select(selectContentLibraryOpenedFile);

    if (openedFile?.type === 'whiteboard' || currentOpenedPanel === 'whiteboard') {
      yield call(openLastActiveWhiteboardSaga);
      return;
    }

    if (currentOpenedPanel === 'contentLibrary') {
      yield call(openLastActiveFileSaga);
      return;
    }
  }

  if (currentOpenedPanel === 'screenshare') {
    yield call(openLastActiveScreenshareSaga);
    return;
  }

  if (!currentOpenedPanel) {
    yield call(findContentForStageSaga);
  }
}
