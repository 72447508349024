import { usePiPWindow } from 'components/PiP/PiPProvider';
import { selectGridPanelMaximized } from 'features/layout/features/content/contentSlice';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useAppSelector } from 'store/hooks';
import { selectAdditionalControlsDisabled } from 'features/layout/selectors';

export const useGridPanelDragHandle = () => {
  const { isMobile } = useBreakpoints();
  const { pipWindow } = usePiPWindow();
  const gridPanelMaximized = useAppSelector(selectGridPanelMaximized);
  const additionalControlsDisabled = useAppSelector(selectAdditionalControlsDisabled);

  const maximizeGridPanel = gridPanelMaximized || isMobile || !!pipWindow;

  return !maximizeGridPanel && !additionalControlsDisabled;
};
