import { PayloadAction } from '@reduxjs/toolkit';
import {
  activeFilesCollectionCleared,
  activeFilesCollectionUpdated,
  activeWhiteboardsCollectionCleared,
  activeWhiteboardsCollectionUpdated,
  fileOpened,
} from 'features/content-library/contentLibrarySlice';
import {
  ContentLibraryOpenedFileEntry,
  ContentLibraryOpenedWhiteboard,
} from 'features/content-library/types';
import {
  gridPanelClosed,
  gridPanelInitialized,
} from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { screenshareCollectionAdded } from 'features/layout/features/order/orderSlice';
import { requestWhiteboardData } from 'features/whiteboard/actions';
import { formatToWhiteboardFile } from 'features/content-library/utils/format';
import { SourceDetails } from 'features/layout/types';
import { RoomJoinedPayload } from 'features/room/types';
import { put } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { loaderDisplayChanged } from 'features/whiteboard/whiteboardSlice';

export function* prepareExposedContentSaga(action: PayloadAction<RoomJoinedPayload>) {
  let latestTimestamp = Number(new Date(0));
  let latestContent: GridPanelName | null = null;

  const availableTabs: Record<GridPanelName, boolean> = {
    contentLibrary: false,
    screenshare: false,
    whiteboard: false,
    external: false,
  };

  const {
    users,
    openedWhiteboards,
    openedFiles,
    user: { id: localUserId },
  } = action.payload;

  const screenshares: (SourceDetails & { timestamp: number })[] = [];

  for (const user of users) {
    if (
      user.screensharing &&
      !!user.screenshareFeedId &&
      user.screenshareStreams?.length &&
      user.startScreenshareTimestamp
    ) {
      const timestamp = user.pushedScreenshareTimestamp || user.startScreenshareTimestamp;

      RTCClient.receivingFeed.availableScreenshares[user.screenshareFeedId] =
        user.screenshareStreams;

      screenshares.push({
        kind: `screenshare-${user.id === localUserId ? 'local' : 'remote'}`,
        userId: user.id,
        feedId: user.screenshareFeedId,
        timestamp,
      });

      if (timestamp > latestTimestamp) {
        latestTimestamp = timestamp;
        latestContent = 'screenshare';
      }
    }
  }

  if (screenshares.length) {
    yield put(screenshareCollectionAdded(screenshares.sort((a, b) => b.timestamp - a.timestamp)));

    availableTabs.screenshare = true;
  } else {
    yield put(screenshareCollectionAdded([]));
    availableTabs.screenshare = false;
  }

  let openedFile: ContentLibraryOpenedFileEntry | null = null;
  let openedWhiteboard: ContentLibraryOpenedWhiteboard | null = null;

  if (openedFiles) {
    const files: Record<string, ContentLibraryOpenedFileEntry> = {};

    for (const file of openedFiles) {
      if (!openedFile) {
        openedFile = file.data as ContentLibraryOpenedFileEntry;
      }

      const timestamp = Number(new Date(file.data.date));

      if (timestamp > latestTimestamp) {
        latestTimestamp = timestamp;
        latestContent = 'contentLibrary';
        openedFile = file.data as ContentLibraryOpenedFileEntry;
      }

      files[file.data.id] = file.data as ContentLibraryOpenedFileEntry;
    }

    yield put(activeFilesCollectionUpdated(files));

    availableTabs.contentLibrary = true;
  } else {
    availableTabs.contentLibrary = false;
    yield put(activeFilesCollectionCleared());
  }

  if (openedWhiteboards) {
    const whiteboards: Record<string, ContentLibraryOpenedWhiteboard> = {};

    for (const whiteboard of openedWhiteboards) {
      if (!openedWhiteboard) {
        openedWhiteboard = whiteboard as ContentLibraryOpenedWhiteboard;
      }

      const timestamp = Number(new Date(whiteboard.date));

      if (timestamp > latestTimestamp) {
        latestTimestamp = timestamp;
        latestContent = 'whiteboard';
        openedWhiteboard = whiteboard as ContentLibraryOpenedWhiteboard;
      }

      whiteboards[whiteboard.id] = whiteboard as ContentLibraryOpenedWhiteboard;
    }

    yield put(activeWhiteboardsCollectionUpdated(whiteboards));

    availableTabs.whiteboard = true;
  } else {
    availableTabs.whiteboard = false;
    yield put(activeWhiteboardsCollectionCleared());
  }

  if (latestContent) {
    if (latestContent === 'contentLibrary' && openedFile) {
      yield put(fileOpened(openedFile));
    }

    if (latestContent === 'whiteboard' && openedWhiteboard) {
      yield put(loaderDisplayChanged(true));
      yield put(fileOpened(formatToWhiteboardFile(openedWhiteboard)));

      yield put(requestWhiteboardData(openedWhiteboard.id));
    }

    yield put(
      gridPanelInitialized({
        activeTab: latestContent,
        availableTabs,
      })
    );
  } else {
    yield put(gridPanelClosed());
  }
}
