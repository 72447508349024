import { call, put } from 'redux-saga/effects';
import { externalStreamDeleted } from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { externalStreamStopped } from 'features/streaming/actions';
import { cleanupAudioStreamSaga } from 'features/streaming/sagas/cleanupAudioStreamSaga';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';

export function* onExternalStreamingStopSaga(action: ReturnType<typeof externalStreamStopped>) {
  const { mid, feedId } = action.payload;

  const stoppedStream: SourceDetails = {
    userId: feedId,
    feedId,
    kind: 'external',
  };

  yield put(gridPanelVoided('external'));

  yield put(externalStreamDeleted(stoppedStream));

  if (action.payload.type === 'video') {
    yield call(RTCClient.receivingFeed.removeStreamTrack, feedId, mid);

    return;
  }

  if (action.payload.type === 'audio') {
    yield call(cleanupAudioStreamSaga, feedId, feedId, mid);
  }

  yield call(eventBus.sendMessage, 'externalStreamStopped');
}
