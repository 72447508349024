import React, { lazy, Suspense } from 'react';
import { selectShowWhiteboardLoader } from 'features/whiteboard/whiteboardSlice';
import { useAppSelector } from 'store/hooks';
import { WhiteboardLoader } from 'features/whiteboard/WhiteboardLoader';

const Whiteboard = lazy(() => import('./Whiteboard/Whiteboard'));

export const WhiteboardPanel = () => {
  const showLoader = useAppSelector(selectShowWhiteboardLoader);

  return (
    <>
      <Suspense fallback={null}>
        <Whiteboard />
      </Suspense>
      {showLoader ? <WhiteboardLoader /> : null}
    </>
  );
};
