import { Box } from '@mui/material';
import { getContrastRatio, styled } from '@mui/material/styles';
import {
  gridPanelTabChanged,
  selectAvailableTabs,
  selectGridPanel,
} from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import {
  selectExternalStreams,
  selectScreenshareStreams,
} from 'features/layout/features/order/orderSlice';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';

const Tab = styled('div')<{ active: boolean }>(({ theme, active }) => {
  const activeColor =
    getContrastRatio(theme.palette.primary.main, theme.palette.common.white) > 1
      ? theme.palette.primary.main
      : theme.palette.common.black;

  return {
    color: active ? activeColor : theme.palette.common.black,
    padding: '.5rem 1rem',
    borderBottom: `2px solid ${active ? activeColor : 'transparent'}`,
    cursor: 'pointer',
  };
});

export const GridPanelTabs = () => {
  const { t } = useTranslation('room');

  const screenshareStreams = useAppSelector(selectScreenshareStreams);
  const externalStreams = useAppSelector(selectExternalStreams);

  const dispatch = useAppDispatch();
  const openTab = useAppSelector(selectGridPanel);
  const availableTabs = useAppSelector(selectAvailableTabs);

  const handleTabChange = (panel: GridPanelName) => {
    dispatch(gridPanelTabChanged(panel));
  };

  const screenshareTabAvailable = screenshareStreams.length > 0;

  const externalStreamsTabAvailable = externalStreams.length > 0;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.grey[600]}`,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
      })}
    >
      {screenshareTabAvailable ? (
        <Tab onClick={() => handleTabChange('screenshare')} active={openTab === 'screenshare'}>
          {t('tabs.screenshare')}
        </Tab>
      ) : null}
      {availableTabs.contentLibrary ? (
        <Tab
          onClick={() => handleTabChange('contentLibrary')}
          active={openTab === 'contentLibrary'}
        >
          {t('tabs.content')}
        </Tab>
      ) : null}
      {availableTabs.whiteboard ? (
        <Tab onClick={() => handleTabChange('whiteboard')} active={openTab === 'whiteboard'}>
          {t('tabs.whiteboard')}
        </Tab>
      ) : null}
      {externalStreamsTabAvailable ? (
        <Tab onClick={() => handleTabChange('external')} active={openTab === 'external'}>
          {t('tabs.stream')}
        </Tab>
      ) : null}
    </Box>
  );
};
