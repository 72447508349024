import { selectMovingBetweenRooms } from 'features/breakout-rooms/breakoutRoomsSlice';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { putScreenshareOnStageSaga } from 'features/layout/sagas/putScreenshareOnStageSaga';
import { LayoutMode } from 'features/layout/types';
import { call, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onStreamMinimizedSaga() {
  const activeMode: LayoutMode = yield select(selectCurrentLayoutMode);

  yield call(eventBus.sendMessage, 'userMinimized', {}, {});

  const movingBetweenRooms: boolean = yield select(selectMovingBetweenRooms);
  if (movingBetweenRooms) {
    return;
  }

  if (activeMode === LayoutMode.tiled) {
    yield call(putScreenshareOnStageSaga);
  } else {
    yield call(findContentForStageSaga, { minimizeStream: false });
  }
}
