import { Box, Divider, ListItemText, MenuItem } from '@mui/material';
import Icon from 'components/Icon';
import Menu from 'components/Menu/Menu';
import { Chip } from 'features/streaming/components/stream-display/StreamBadge';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { useState } from 'react';
import { SxProps } from '@mui/material/styles';

type ExtraItem = {
  title: string;
  action: () => void;
};

type PanelContentSelectorProps = {
  extraItems?: ExtraItem[];
  items: any[];
  activeItem: any;
  sx?: SxProps;
  renderLabel: (item: any) => string;
  onSelect: (idx: number) => void;
};

export const PanelContentSelector = ({
  extraItems = [],
  items,
  activeItem,
  onSelect,
  renderLabel,
  sx = [],
}: PanelContentSelectorProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);

  const { isMobile } = useBreakpoints();

  const isDropDownAvailable = items.length > 1 || extraItems?.length;

  return (
    <Box
      className={!isMobile ? 'hover-child' : ''}
      sx={[
        {
          maxWidth: 'calc(100% - 100px)',
          position: 'absolute',
          top: (theme) => theme.spacing(2),
          left: '50%',
          width: 'auto',
          transform: 'translateX(-50%)',
          textAlign: isMobile ? 'left' : 'center',
          padding: (theme) => theme.spacing(0, 2),
          zIndex: 1000,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Menu
        id="file-panel-content-selector"
        open={menuOpen}
        onClose={closeMenu}
        placement="bottom"
        PopperProps={{
          disablePortal: true,
          sx: {
            maxWidth: 'calc(100vw - 100px)',
          },
        }}
        trigger={
          <button
            type="button"
            className="button-appearance-none"
            style={{ padding: 0, cursor: 'pointer', maxWidth: '100%' }}
            onClick={() => {
              if (isDropDownAvailable) {
                setMenuOpen((open) => !open);
              } else {
                setMenuOpen(false);
              }
            }}
          >
            <Chip
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {renderLabel(activeItem)}
                  </span>
                  {isDropDownAvailable ? (
                    <Icon
                      name="dropdown-arrow"
                      sx={{
                        flexShrink: 0,
                        fontSize: '32px',
                        marginRight: '-10px',
                        transform: 'translateY(-2px)',
                      }}
                    />
                  ) : null}
                </div>
              }
            />
          </button>
        }
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.grey['900'],
          }),
        }}
        sx={(theme) => ({
          color: theme.palette.common.white,
        })}
      >
        {extraItems.map((item) => (
          <MenuItem key={item.title} onClick={item.action}>
            <ListItemText
              sx={{
                '& span': {
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                },
              }}
            >
              {item.title}
            </ListItemText>
          </MenuItem>
        ))}
        <Divider hidden={extraItems.length === 0} />
        <Box sx={{ maxHeight: 'calc(min(285px, 25vh))', overflow: 'auto', maxWidth: '100%' }}>
          {items.map((row, idx) =>
            row ? (
              <MenuItem key={idx} onClick={() => onSelect(idx)}>
                <ListItemText
                  sx={{
                    '& span': {
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    },
                  }}
                >
                  {renderLabel(row)}
                </ListItemText>
              </MenuItem>
            ) : null
          )}
        </Box>
      </Menu>
    </Box>
  );
};
