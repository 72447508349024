import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { externalStreamAdded } from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { externalStreamStarted } from 'features/streaming/actions';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';

export function* onExternalStreamingStartSaga(action: ReturnType<typeof externalStreamStarted>) {
  const { feedId, streams } = action.payload;

  yield call(RTCClient.receivingFeed.addAvailableStreams, feedId, streams);

  yield call(
    RTCClient.subscribe,
    {
      feedId,
      streams,
      id: feedId,
    },
    'external'
  );

  const source: SourceDetails = {
    userId: feedId,
    feedId,
    kind: 'external',
  };

  yield put(externalStreamAdded(source));

  yield put(gridPanelOpened('external'));

  const pinnedContentWidth: number = yield select(selectPinnedContentWidth);
  yield put(contentWidthChanged(pinnedContentWidth));

  yield call(eventBus.sendMessage, 'externalStreamStarted');
}
