import { PayloadAction } from '@reduxjs/toolkit';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { SourceDetails } from 'features/layout/types';
import { call } from 'redux-saga/effects';

export function* onOrderedStreamDeletedSaga(action: PayloadAction<SourceDetails>) {
  yield call(findContentForStageSaga, {
    includePastScreenshare: false,
    deletedStream: action.payload,
  });
}
