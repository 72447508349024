import { call, delay, race, select, take } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { signalingWhiteboardKeyShared } from 'features/e2ee/actions';
import { SignalingSocket } from 'services/signaling';
import { logger } from 'utils/logger';
import { signalingWhiteboardOpened } from 'features/layout/features/content/actions';
import { WhiteboardKeySharedPayload } from 'features/e2ee/types';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectRoomId } from 'features/room/roomSlice';

export function* requestWhiteboardKeySaga(action: ReturnType<typeof signalingWhiteboardOpened>) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (!e2eeEnabled) {
    return;
  }

  const {
    data: { id },
    initiator,
  } = action.payload;

  const roomId: string = yield select(selectRoomId);

  const existingKey: AesCm256EncryptionKey | undefined = yield call(
    E2EEManager.whiteboard.getKey,
    id,
    roomId
  );

  if (existingKey) {
    // skip if we know a key of the opened whiteboard
    return;
  }

  const localUserId: UserId = yield select(selectLocalUserId);
  if (localUserId === initiator.id) {
    // skip this saga for whiteboard creator
    return;
  }

  // wait for the shared key or timeout after 2 seconds
  const [timeout]: [boolean, WhiteboardKeySharedPayload] = yield race([
    delay(2000),
    take(
      (event: any) => event.type === signalingWhiteboardKeyShared.type && event.payload.id === id
    ),
  ]);

  if (!timeout) {
    // we received the expected key, no need to request it manually
    return;
  }

  // we haven't received a whiteboard key, request it manually
  logger
    .remote({ system: true, capture: 'e2ee' })
    .debug(
      `Whiteboard key hasn't been shared after timeout, requesting the key of whiteboard id=${id}`
    );

  yield call(SignalingSocket.send, {
    event: 'requestWhiteboardKey',
    data: {
      id,
    },
  });

  // check for late arrival of `whiteboardKeyShared` event after requesting
  const [receivedAfterRequest]: [WhiteboardKeySharedPayload, boolean] = yield race([
    take(
      (event: any) =>
        event.type === signalingWhiteboardKeyShared.type && event.payload.whiteboardId === id
    ),
    delay(2000),
  ]);

  if (receivedAfterRequest) {
    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug(`Whiteboard key shared after and requesting for whiteboard id=${id}`);
  } else {
    Sentry.captureException(
      new Error(
        `Whiteboard key NOT shared after both initial timeout and re-request for whiteboard id=${id}`
      )
    );
  }
}
