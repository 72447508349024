import { screenshareSelected } from 'features/streaming/actions';
import {
  selectedScreenshareChanged,
  selectSelectedScreenshare,
} from 'features/streaming/streamingSlice';
import { SelectedScreenshare } from 'features/streaming/types';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { batchToggleMediaTemplate, sendMessage } from 'utils/webrtc/messages';

export function* onScreenshareSelectedSaga(action: ReturnType<typeof screenshareSelected>) {
  const { userId, feedId, initial, reinit } = action.payload;
  const localUserId: UserId = yield select(selectLocalUserId);

  const previousScreenshare: SelectedScreenshare | null = yield select(selectSelectedScreenshare);

  if (userId === previousScreenshare?.userId && previousScreenshare.feedId === feedId) {
    return;
  }

  const feed = RTCClient.receivingFeed.receiver?.plugin?.janusPlugin;

  // unsubscribe from previous screenshare;
  if (previousScreenshare && !reinit) {
    const { streams } = RTCClient.receivingFeed.media.getFeedStreams(previousScreenshare.feedId);

    const request = Object.values(streams).map((stream) => ({
      mid: stream.subscriberMid || '-1',
      send: false,
    }));

    if (feed) {
      yield call(sendMessage, feed, batchToggleMediaTemplate(request));
    }
  }

  const availableStreams = RTCClient.receivingFeed.availableScreenshares[feedId];
  const hasVideoStream = availableStreams?.some((item) => item.type === 'video');

  if (localUserId !== userId) {
    if (RTCClient.receivingFeed.subscribedScreenshares[feedId]) {
      const { streams } = RTCClient.receivingFeed.media.getFeedStreams(feedId);

      const request = Object.values(streams).map((stream) => ({
        mid: stream.subscriberMid || '-1',
        send: true,
      }));

      if (feed) {
        yield call(sendMessage, feed, batchToggleMediaTemplate(request));
      }
    } else if (availableStreams?.length) {
      if (hasVideoStream || !initial) {
        RTCClient.receivingFeed.subscribedScreenshares[feedId] = true;
      }

      yield call(
        RTCClient.subscribe,
        {
          feedId,
          streams: availableStreams,
          id: userId,
        },
        'screenshare'
      );

      yield call(RTCClient.enableScreensharingMedia, userId, availableStreams);
    }
  }

  if ((hasVideoStream || !initial) && !reinit) {
    yield put(selectedScreenshareChanged({ userId, feedId }));
  }
}
