import { PayloadAction } from '@reduxjs/toolkit';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { LayoutMode } from 'features/layout/types';
import { streamMinimized } from 'features/layout/actions';
import { call, put } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onLayoutModeChangedSaga(action: PayloadAction<LayoutMode>) {
  yield call(eventBus.sendMessage, 'layoutModeChanged', {}, { mode: action.payload });

  if (action.payload === LayoutMode.auto) {
    yield call(findContentForStageSaga, { includePastScreenshare: true });
  } else {
    yield put(streamMinimized());
  }
}
