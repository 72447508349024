import { useAppSelector } from 'store/hooks';
import {
  selectRemoteScreensharingState,
  selectRemoteStreams,
} from 'features/streaming/streamingSlice';
import StreamMedia from 'features/streaming/components/stream-display/StreamMedia';
import { StreamingMediaProps } from 'features/streaming/types';
import RemoteVideoWrapper from 'features/streaming/components/media/remote-stream/RemoteVideoWrapper';
import { useStreamFitment } from 'hooks/useStreamFitment';

const RemoteScreenshare = ({ source, avatarSize }: StreamingMediaProps) => {
  const fitment = useStreamFitment(source);

  const streams = useAppSelector((state) =>
    selectRemoteStreams(state, source.userId, source.feedId)
  );

  const isVideoBroadcasting = useAppSelector((state) =>
    selectRemoteScreensharingState(state, source.userId, 'video')
  );

  return (
    <StreamMedia source={source} avatarSize={avatarSize}>
      {streams?.map((stream) =>
        stream.kind === 'video' && isVideoBroadcasting ? (
          <RemoteVideoWrapper
            mode="screenshare"
            key={stream.mid}
            mid={stream.mid}
            userId={source.userId}
            feedId={source.feedId}
            className={`fitment-${fitment}`}
          />
        ) : null
      )}
    </StreamMedia>
  );
};

export default RemoteScreenshare;
