import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Box, Typography } from '@mui/material';
import { closeModal } from 'features/modal/modalSlice';
import React, { useEffect, useId } from 'react';
import CodeBox from 'components/CodeBox';
import CopyButton from 'components/CopyButton/CopyButton';
import { selectInviteAvailable } from 'features/permissions/permissionsSlice';
import Icon from 'components/Icon';

const InviteUserModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const isInviteAvailable = useAppSelector(selectInviteAvailable);

  const prefix = useId();

  useEffect(() => {
    if (!isInviteAvailable) {
      dispatch(closeModal('inviteUser'));
    }
  }, [isInviteAvailable, dispatch]);

  const handleClose = () => {
    dispatch(closeModal('inviteUser'));
  };

  const roomUrl = `${window.location.origin}${window.location.pathname}`;

  return (
    <Dialog
      open
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
    >
      <DialogTitle id={`${prefix}-title`} onClose={handleClose}>
        {t('invite_participants.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${prefix}-description`}>
          {t('invite_participants.description')}
        </DialogContentText>
        <Box
          data-testid="code-box"
          sx={{
            mt: 3,
          }}
        >
          <CodeBox
            sx={{
              overflowX: 'auto',
              display: 'flex',
              width: '100%',
            }}
          >
            <Typography>{roomUrl}</Typography>
          </CodeBox>
        </Box>
      </DialogContent>
      <DialogActions>
        <CopyButton text={roomUrl} autoFocus endIcon={<Icon name="copy" />} startIcon={null}>
          {(isCopied) =>
            isCopied
              ? t('invite_participants.btn_copied_label')
              : t('invite_participants.btn_copy_label')
          }
        </CopyButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserModal;
