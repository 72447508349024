import { call } from 'redux-saga/effects';
import { FeedId, SubscriberMid } from 'features/streaming/types';
import { UserId } from 'features/users/types';
import { StreamingReceiver } from 'utils/webrtc/receiving/StreamingReceiver';
import { RTCClient } from 'utils/webrtc';

export function* cleanupAudioStreamSaga(userId: UserId, feedId: FeedId, mid: SubscriberMid) {
  if (RTCClient.receivingFeed.receiver instanceof StreamingReceiver) {
    yield call(RTCClient.receivingFeed.unloadFeedAudio, userId);
    yield call(RTCClient.receivingFeed.media.freeStreamBySubscribedMid, feedId, mid);
    yield call(RTCClient.receivingFeed.updateRedux);
  }
}
