import { styled } from '@mui/material/styles';
import { selectRemoteStreams } from 'features/streaming/streamingSlice';
import { StreamingMediaProps } from 'features/streaming/types';
import RemoteVideoWrapper from 'features/streaming/components/media/remote-stream/RemoteVideoWrapper';
import { useAppSelector } from 'store/hooks';
import { useStreamFitment } from 'hooks/useStreamFitment';
import Icon from 'components/Icon';
import { Theme, useMediaQuery } from '@mui/material';

export const PlaceholderWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  pointerEvents: 'none',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: theme.room.tileBackground,
  fontSize: '192px',
  color: 'white',
  '&.mobile': {
    fontSize: '140px',
  },
}));

const ExternalStream = ({ source }: StreamingMediaProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const fitment = useStreamFitment(source);

  const streams = useAppSelector((state) =>
    selectRemoteStreams(state, source.userId, source.feedId)
  );

  return (
    <>
      <PlaceholderWrapper className={isMobile ? 'mobile' : ''}>
        <Icon name="streaming" />
      </PlaceholderWrapper>
      {streams?.map((stream) =>
        stream.kind === 'video' ? (
          <RemoteVideoWrapper
            mode="external"
            key={stream.mid}
            mid={stream.mid}
            userId={source.userId}
            feedId={source.feedId}
            className={`fitment-${fitment}`}
          />
        ) : null
      )}
    </>
  );
};

export default ExternalStream;
