import { call, put, select } from 'redux-saga/effects';
import { signalingWhiteboardDataReceived } from 'features/whiteboard/actions';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import {
  activeWhiteboardsCollectionUpdated,
  selectActiveWhiteboard,
} from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedWhiteboard } from 'features/content-library/types';
import { board, WhiteboardData } from 'utils/whiteboard/BoardStateManager';
import { parseWhiteboardStateSaga } from 'features/whiteboard/sagas/parseWhiteboardStateSaga';
import { manageWhiteboardEncryptionSaga } from 'features/whiteboard/sagas/manageWhiteboardEncryptionSaga';
import { loaderDisplayChanged } from 'features/whiteboard/whiteboardSlice';

export function* onWhiteboardDataReceivedSaga(
  action: ReturnType<typeof signalingWhiteboardDataReceived>
) {
  yield put(loaderDisplayChanged(true));

  const {
    data: { id, shapes, name },
    initiator,
  } = action.payload;

  yield call(manageWhiteboardEncryptionSaga, id, initiator);

  const whiteboardData: WhiteboardData = {
    id,
    name,
    shapes: [],
  };
  if (shapes.length) {
    whiteboardData.shapes = yield call(parseWhiteboardStateSaga, id, shapes);
  }

  yield call(board.init, whiteboardData);

  const currentWhiteboardData: ContentLibraryOpenedWhiteboard = yield select(
    selectActiveWhiteboard,
    id
  );

  yield put(
    activeWhiteboardsCollectionUpdated({
      [id]: {
        ...currentWhiteboardData,
        ...action.payload.data,
      },
    })
  );

  const canEditWhiteboard: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard);

  if (canEditWhiteboard) {
    yield call(board.watch);
  } else {
    yield call(board.stop);
  }

  yield put(loaderDisplayChanged(false));
}
