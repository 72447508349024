import { put, select } from 'redux-saga/effects';
import { fileViewed } from 'features/content-library/actions';
import { fileOpened, selectActiveWhiteboard } from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedWhiteboard } from 'features/content-library/types';
import { requestWhiteboardData } from 'features/whiteboard/actions';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* onWhiteboardViewedSaga(action: ReturnType<typeof fileViewed>) {
  const id = action.payload;
  const whiteboard: ContentLibraryOpenedWhiteboard | undefined = yield select(
    selectActiveWhiteboard,
    id
  );

  if (whiteboard) {
    const previousWhiteboardId = board.whiteboardId;

    yield put(fileOpened(whiteboard));

    if (previousWhiteboardId !== whiteboard.id) {
      yield put(requestWhiteboardData(whiteboard.id));
    }
  }
}
