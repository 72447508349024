export enum FeatureFlag {
  languageSelection = 'language_selection',
  screenshare = 'screenshare',
  participantsList = 'participants_list',
  generalChat = 'general_chat',
  privateChat = 'private_chat',
  privateGroupChat = 'private_group_chat',
  qa = 'qa',
  contentLibrary = 'content_library',
  whiteboard = 'whiteboard',
  pin = 'pin',
  fullScreen = 'full_screen',
  minimizeOwnTile = 'minimize_own_tile',
  endSession = 'end_session',
  leaveSession = 'leave_session',
  recordings = 'recordings',
  captions = 'captions',
  virtualBackgrounds = 'virtual_backgrounds',
  raiseHand = 'raise_hand',
  invite = 'invite',
  breakoutRooms = 'breakout_rooms',
  filesPanel = 'files_panel',
  polls = 'polls',
  pictureInPicture = 'picture_in_picture',
  sharedNotes = 'shared_notes',
  muteSound = 'mute_sound',
}

export type FeatureFlagType = keyof typeof FeatureFlag;

export type FeatureFlagValue = `${FeatureFlag}`;

export type FeatureFlags = Record<FeatureFlagType, boolean>;

export interface FeaturesState {
  initialized: boolean;
  flags: FeatureFlags;
}

export interface UpdateFeatureFlagPayload {
  feature: FeatureFlagValue;
}
