import { ChipButton, ChipGroup, ChipLabel } from 'components/ChipGroup';
import { localStreamExpanded } from 'features/layout/features/config/configSlice';
import { useLocalVideo } from 'hooks/useLocalVideo';
import { useAppDispatch } from 'store/hooks';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip';
import { useLocalAudio } from 'hooks/useLocalAudio';
import Icon from 'components/Icon';

const Root = styled(Box)({
  position: 'absolute',
  bottom: 8,
  right: 8,
  zIndex: 500,
});

export const MinimizedLocalStream = () => {
  const { t } = useTranslation(['room', 'common']);
  const dispatch = useAppDispatch();

  const { toggleMic, micColor, audioTooltipText, micIcon } = useLocalAudio();
  const { toggleVideo, camColor, videoTooltipText, camIcon } = useLocalVideo();

  const handleMaximizeClick = () => {
    dispatch(localStreamExpanded());
  };

  return (
    <Root>
      <ChipGroup>
        <Tooltip title={audioTooltipText} placement="top">
          <ChipButton sx={{ ml: '5px', color: micColor }} onClick={toggleMic}>
            <Icon name={micIcon} />
          </ChipButton>
        </Tooltip>
        <Tooltip title={videoTooltipText} placement="top">
          <ChipButton sx={{ ml: '5px', color: camColor }} onClick={toggleVideo}>
            <Icon name={camIcon} />
          </ChipButton>
        </Tooltip>
        <ChipLabel>{t('common:you')}</ChipLabel>
        <Tooltip title={t('room:tooltips.maximize_own')} placement="top">
          <ChipButton sx={{ mr: '5px' }} onClick={handleMaximizeClick}>
            <Icon name="maximize" />
          </ChipButton>
        </Tooltip>
      </ChipGroup>
    </Root>
  );
};
