import React, { useEffect } from 'react';
import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { selectExternalStreams } from 'features/layout/features/order/orderSlice';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import RemoteAudioWrapper from 'features/streaming/components/media/remote-stream/RemoteAudioWrapper';
import StreamDisplay from 'features/streaming/components/stream-display/StreamDisplay';
import BaseLoadingIndicator from 'features/room/components/BaseLoadingIndicator';
import { makeSourceKey } from 'features/streaming/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ExternalStreamPanel = () => {
  const dispatch = useAppDispatch();

  const externalStreams = useAppSelector(selectExternalStreams);

  useEffect(() => {
    if (!externalStreams.length) {
      dispatch(gridPanelVoided('external'));
    }
  }, [externalStreams, dispatch]);

  const activeExternalStreamEntry = externalStreams.length
    ? externalStreams[externalStreams.length - 1]
    : null;

  return (
    <GridPanelContainer
      sx={{ alignItems: 'center', justifyContent: 'center' }}
      id="external-stream-container"
    >
      {activeExternalStreamEntry ? (
        <>
          <StreamDisplay source={activeExternalStreamEntry} maximized />
          <RemoteAudioWrapper
            source={activeExternalStreamEntry}
            key={makeSourceKey(activeExternalStreamEntry)}
          />
        </>
      ) : (
        <BaseLoadingIndicator />
      )}
    </GridPanelContainer>
  );
};
