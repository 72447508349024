import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import { selectDeviceType } from 'features/application/applicationSlice';
import { ContentPanel } from 'features/content-library/components/ContentPanel/ContentPanel';
import {
  selectGridPanel,
  selectGridPanelMaximized,
  selectMaximizedContent,
  selectShowGridPanelTabs,
} from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { selectContentWidth } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { GridPanelControls } from 'features/layout/GridPanel/GridPanelControls';
import { GridPanelTabs } from 'features/layout/GridPanel/GridPanelTabs';
import { useGridPanelDragHandle } from 'features/layout/GridPanel/useGridPanelDragHandle';
import { ScreensharePanel } from 'features/layout/layouts/ScreensharePanel';
import { ExternalStreamPanel } from 'features/layout/layouts/ExternalStreamPanel';
import { hoverParentStyles, useHoverParent } from 'features/layout/useHoverParent';
import { WhiteboardPanel } from 'features/whiteboard/WhiteboardPanel';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import { selectPageStreams } from 'features/layout/features/pagination/paginationSlice';
import { LayoutMode } from 'features/layout/types';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { ComponentType, ForwardedRef, useMemo, useState } from 'react';
import { Axis, Resizable, ResizeCallbackData } from 'react-resizable';
import { useAppSelector } from 'store/hooks';
import { layoutConfig } from 'utils/layout';
import cx from 'clsx';

const Panel = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  padding: `${theme.room.tileGap}px`,
}));

const HandleOuter = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'show',
})<{ show?: boolean }>(({ theme, show }) => ({
  height: '100%',
  width: '10px',
  position: 'absolute',
  right: 0,
  top: 0,
  display: show ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'ew-resize',
  zIndex: 2,
  '.resize-pill': {
    opacity: 1,
    height: '36px',
    width: '6px',
    borderRadius: '.25rem',
    border: `1px solid ${theme.room.tileBackground}`,
    backgroundColor: '#fff',
  },
}));

const PanelInner = styled('div')({
  height: '100%',
  ...hoverParentStyles,
});

const CustomHandle = React.forwardRef(
  (props: Partial<{ handleAxis: Axis; show: boolean }>, ref: ForwardedRef<HTMLDivElement>) => {
    const { handleAxis, show, ...restProps } = props;
    return (
      <HandleOuter ref={ref} className={`handle-${handleAxis}`} show={show} {...restProps}>
        <Box className="resize-pill" />
      </HandleOuter>
    );
  }
);

const panelMap: Record<
  GridPanelName,
  ComponentType<{ size: { width?: number; height?: number } }>
> = {
  whiteboard: WhiteboardPanel,
  contentLibrary: ContentPanel,
  screenshare: ScreensharePanel,
  external: ExternalStreamPanel,
};

interface GridPanelProps {
  roomSize: {
    width?: number;
    height?: number;
  };
}

export const GridPanel = ({
  roomSize: { width: roomWidth, height: roomHeight },
}: GridPanelProps) => {
  const containerWidth = roomWidth || window.innerWidth;
  const maximizedContent = useAppSelector(selectMaximizedContent);
  const contentWidth = useAppSelector(selectContentWidth);
  const gridPanelMaximized = useAppSelector(selectGridPanelMaximized);
  const currentLayoutMode = useAppSelector(selectCurrentLayoutMode);

  const defaultPanelWidth = containerWidth * layoutConfig.defaultPinWidth;

  const [panelSize, setPanelSize] = useState({
    width: defaultPanelWidth,
    height: roomHeight,
  });

  const hasDragHandle = useGridPanelDragHandle();

  const maxContentWidth = containerWidth - layoutConfig.minSidebarWidth;

  const activePanel = useAppSelector(selectGridPanel);
  const showTabs = useAppSelector(selectShowGridPanelTabs);

  const { isMobile } = useBreakpoints();
  const { pipWindow } = usePiPWindow();
  const streams = useAppSelector(selectPageStreams);

  const maximizeGridPanel =
    (gridPanelMaximized && currentLayoutMode === LayoutMode.tiled) ||
    isMobile ||
    !!pipWindow ||
    !streams.length;

  const deviceType = useAppSelector(selectDeviceType);

  const width = useMemo(
    () =>
      currentLayoutMode === LayoutMode.auto
        ? defaultPanelWidth
        : Math.min(panelSize.width, maxContentWidth),
    [panelSize, maxContentWidth, currentLayoutMode, defaultPanelWidth]
  );

  const { className, ...mouseCallbacks } = useHoverParent('gridPanel');

  if (!activePanel || (maximizedContent === 'stream' && contentWidth === 1)) {
    return null;
  }

  if (!(roomWidth && roomHeight)) {
    return null;
  }

  const onResize = (data: ResizeCallbackData) => {
    setPanelSize(data.size);
  };

  const ActivePanel = panelMap[activePanel];

  return (
    <Resizable
      width={width}
      height={panelSize.height}
      onResize={(e, data) => onResize(data)}
      maxConstraints={[maxContentWidth, roomHeight]}
      minConstraints={[Math.max(containerWidth * layoutConfig.minContentWidth, 420), 0]}
      resizeHandles={deviceType === 'mobile' || isMobile || !!pipWindow ? [] : ['e']}
      axis="x"
      handle={<CustomHandle show={hasDragHandle} />}
    >
      <Panel
        className={cx(className, { 'fullscreen-panel': maximizeGridPanel }, 'grid-panel')}
        sx={(theme) => ({
          minWidth: width,
          paddingRight: hasDragHandle
            ? `${theme.room.tileGap * 4 - 2}px`
            : `${theme.room.tileGap}px`,
        })}
        {...mouseCallbacks}
      >
        <PanelInner>
          {showTabs && <GridPanelTabs />}
          <Box
            sx={{
              height: showTabs ? 'calc(100% - 40px)' : '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            <ActivePanel size={{ width: roomWidth, height: roomHeight - 41 }} />
          </Box>
          <GridPanelControls activePanel={activePanel} />
        </PanelInner>
      </Panel>
    </Resizable>
  );
};
