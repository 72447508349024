import React from 'react';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { whiteboardClosed } from 'features/layout/features/content/actions';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useRecorder } from 'features/recorder/useRecorder';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';
import { openModal } from 'features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useWhiteboardCloseControl = () => {
  const dispatch = useAppDispatch();
  const isRecorder = useRecorder();

  const openedFile = useAppSelector(selectContentLibraryOpenedFile);

  const { hasPermissions } = usePermissions();

  const canEditWhiteboard = hasPermissions(PermissionTypes.editWhiteboard);

  const isFeatureEnabled = useFeatureFlag('whiteboard');
  const isContentLibraryEnabled = useFeatureFlag('contentLibrary');

  const controlEnabled = isRecorder ? false : isFeatureEnabled && canEditWhiteboard;

  const canPresentContentLibrary = hasPermissions(PermissionTypes.presentContentLibrary);
  const isContentLibraryFeatureAvailable = isContentLibraryEnabled && canPresentContentLibrary;

  const handleClick = () => {
    if (openedFile?.id) {
      if (!isContentLibraryFeatureAvailable) {
        dispatch(openModal('closeWhiteboardConfirmation'));
      } else {
        dispatch(whiteboardClosed(openedFile?.id));
      }
    }
  };

  return {
    controlEnabled,
    handleClick,
  };
};

export const WhiteboardCloseControl = () => {
  const { controlEnabled, handleClick } = useWhiteboardCloseControl();

  return controlEnabled ? <GridPanelControl icon="close" onClick={handleClick} /> : null;
};
