import { PayloadAction } from '@reduxjs/toolkit';
import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import {
  screenshareAdded,
  selectScreenshareStreams,
} from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { screenshareSelected } from 'features/streaming/actions';
import { SelectedScreenshare, StreamingStarted } from 'features/streaming/types';
import { selectSelectedScreenshare } from 'features/streaming/streamingSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';

export function* onScreensharingStartSaga(action: PayloadAction<StreamingStarted>) {
  const { id: userId, feedId, streams } = action.payload;
  const currentSelectedScreenshare: SelectedScreenshare | null =
    yield select(selectSelectedScreenshare);

  const isExistScreensharingStream = streams.some(
    (stream) => RTCClient.screensharingByUserId[userId]?.[stream.type]
  );
  const isLocalScreensharingReconnecting = Boolean(
    RTCClient.localFeeds[feedId] && RTCClient.screensharingFeed.isReconnecting
  );
  const isNotCurrentSelectedScreenshare = Boolean(
    currentSelectedScreenshare &&
      currentSelectedScreenshare.feedId !== feedId &&
      currentSelectedScreenshare.userId !== userId
  );

  yield call(RTCClient.receivingFeed.addAvailableStreams, feedId, streams);
  RTCClient.receivingFeed.availableScreenshares[feedId] = streams;

  yield put(
    screenshareSelected({
      userId,
      feedId,
      initial: true,
      reinit:
        (isExistScreensharingStream || isLocalScreensharingReconnecting) &&
        isNotCurrentSelectedScreenshare,
    })
  );

  const hasVideoStream = streams?.some((item) => item.type === 'video');

  if (
    RTCClient.localFeeds[feedId] &&
    RTCClient.screensharingFeed.isReconnecting &&
    hasVideoStream
  ) {
    RTCClient.screensharingFeed.isReconnecting = false;
  }

  const source: SourceDetails = {
    userId: action.payload.id,
    feedId,
    kind: `screenshare-${RTCClient.localFeeds[feedId] ? 'local' : 'remote'}`,
  };

  const screenshares: SourceDetails[] = yield select(selectScreenshareStreams);

  const knownScreenshare = screenshares.find((stream) => stream.feedId === feedId);

  yield put(screenshareAdded(source));

  if (!knownScreenshare) {
    yield put(gridPanelOpened('screenshare'));
  }

  // TODO;

  const pinnedContentWidth: number = yield select(selectPinnedContentWidth);
  yield put(contentWidthChanged(pinnedContentWidth));
  yield call(eventBus.sendMessage, 'screenshareStarted', { userId });
}
