export enum PermissionTypes {
  broadcast = 'broadcast',
  manageBroadcast = 'manage_broadcast',
  endSession = 'end_session',
  startSession = 'start_session',
  removeParticipant = 'remove_participant',
  screenshare = 'screenshare',
  manageScreenshare = 'manage_screenshare',
  recording = 'recording',
  generalChat = 'general_chat',
  privateChat = 'private_chat',
  deleteOthersChatMessages = 'delete_others_chat_messages',
  exportChat = 'export_chat',
  clearChat = 'clear_chat',
  remoteMuting = 'remote_muting',
  askRemoteUnmute = 'ask_remote_unmute',
  raiseHand = 'raise_hand',
  manageRoles = 'manage_roles',
  manageEditWhiteboard = 'manage_edit_whiteboard',
  inviteParticipant = 'invite_participant',
  seeParticipantsPanel = 'see_participants_panel',
  controlRoomEntry = 'control_room_entry',
  editWhiteboard = 'edit_whiteboard',
  manageBreakout = 'manage_breakout',
  moderateQA = 'moderate_qa',
  answerQA = 'answer_qa',
  downloadQa = 'download_qa',
  seeAllQuestions = 'see_all_questions',
  controlRoomComponents = 'control_room_components',
  presentFiles = 'present_files',
  managePolls = 'manage_polls',
  takePolls = 'take_polls',
  upvoteQA = 'upvote_qa',
  transcribeSession = 'transcribe_session',
  manageContentLibrary = 'manage_content_library',
  presentContentLibrary = 'present_content_library',
  managePersonalContentLibrary = 'manage_personal_content_library',
  pushContent = 'push_content',
  seeNotes = 'see_notes',
  collaborateInNotes = 'collaborate_in_notes',
}

export type RoleEntries = Record<string, Role>;

export interface Role {
  name: string;
  displayNameKey?: string;
  displayName: string;
  permissions: Permission[];
}

export interface Permission {
  name: PermissionTypes;
  targetRoles?: string[];
}

export type RolePermissions = Record<string, Record<string, boolean>>;
export type TargetRolesMap = Record<string, Partial<Record<string, string[]>>>;
export type CoverageAcrossRoles = Record<string, boolean>;

export interface PermissionsState {
  byRole: RoleEntries;
  allRoles: string[];
  rolePermissions: RolePermissions;
  targetRolesMap: TargetRolesMap;
  coverageAcrossRoles: CoverageAcrossRoles;
}
