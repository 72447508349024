import { put, select } from 'redux-saga/effects';
import { streamMinimized } from 'features/layout/actions';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import { LayoutMode, SourceDetails } from 'features/layout/types';

export function* onGridPanelOpenedSaga() {
  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);
  const currentLayoutMode: LayoutMode = yield select(selectCurrentLayoutMode);

  if (maximizedStream && currentLayoutMode === LayoutMode.auto) {
    yield put(streamMinimized());
  }
}
