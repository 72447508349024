import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { LayoutOrderState } from 'features/layout/features/order/types';
import { SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { selectUsers } from 'features/users/usersSlice';
import { RootState } from 'store/store';

export const initialState: LayoutOrderState = {
  orderedStreams: [],
  screenshareStreams: [],
  externalStreams: [],
  historicalSpeakers: [],
};

const layoutOrderSlice = createSlice({
  name: 'layoutOrder',
  initialState,
  reducers: {
    orderUpdated: {
      reducer(state, action: PayloadAction<SourceDetails[]>) {
        state.orderedStreams = action.payload;
      },
      prepare: prepareAutoBatched<SourceDetails[]>(),
    },
    orderedStreamAdded(state, action: PayloadAction<SourceDetails>) {
      state.orderedStreams.push(action.payload);
    },
    orderedStreamDeleted(state, action: PayloadAction<SourceDetails>) {
      state.orderedStreams = state.orderedStreams.filter(
        (stream) => !sourceMatches(stream, action.payload)
      );
    },
    screenshareAdded(state, action: PayloadAction<SourceDetails>) {
      state.screenshareStreams = state.screenshareStreams.filter(
        (stream) => stream.userId !== action.payload.userId
      );

      state.screenshareStreams.push(action.payload);
    },
    screenshareDeleted(state, action: PayloadAction<SourceDetails>) {
      state.screenshareStreams = state.screenshareStreams.filter(
        (stream) => !sourceMatches(stream, action.payload)
      );
    },
    screenshareCollectionAdded(state, action: PayloadAction<SourceDetails[]>) {
      // only doing this once per room, shouldn’t be an issue;
      // state.screenshareStreams = [...new Set([...state.screenshareStreams, ...action.payload])];

      // replace to cleanup on reconnect; switch back to spread approach if this causes issues;
      state.screenshareStreams = action.payload;
    },
    externalStreamAdded(state, action: PayloadAction<SourceDetails>) {
      const isExist = state.externalStreams.some(
        (stream) => stream.feedId === action.payload.feedId
      );

      if (!isExist) {
        state.externalStreams.push(action.payload);
      }
    },
    externalStreamDeleted(state, action: PayloadAction<SourceDetails>) {
      state.externalStreams = state.externalStreams.filter(
        (stream) => stream.feedId !== action.payload.feedId
      );
    },
    speakersUpdated: {
      reducer(state, action: PayloadAction<SourceDetails[]>) {
        state.historicalSpeakers = action.payload;
      },
      prepare: prepareAutoBatched<SourceDetails[]>(),
    },
  },
});

export const {
  orderUpdated,
  orderedStreamAdded,
  orderedStreamDeleted,
  speakersUpdated,
  screenshareDeleted,
  screenshareAdded,
  screenshareCollectionAdded,
  externalStreamAdded,
  externalStreamDeleted,
} = layoutOrderSlice.actions;

export const selectOrderedStreams = (state: RootState) => state.layout.order.orderedStreams;
export const selectScreenshareStreams = createSelector(
  [selectUsers, (state: RootState) => state.layout.order.screenshareStreams],
  (users, screenshares) =>
    screenshares
      .map((screenshare) =>
        users[screenshare.userId]
          ? {
              ...screenshare,
              user: users[screenshare.userId],
            }
          : undefined
      )
      .filter(Boolean)
);

export const selectExternalStreams = (state: RootState) => state.layout.order.externalStreams;

export const selectHistoricalSpeakers = (state: RootState) => state.layout.order.historicalSpeakers;

export const selectActiveSpeaker = (state: RootState) =>
  state.layout.order.orderedStreams.find(
    (source) => source.userId === state.streaming.activeSpeakerId
  );

export const selectActiveSpeakerId = (state: RootState) => state.streaming.activeSpeakerId;

export default layoutOrderSlice.reducer;
