import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { signalingRoomJoined } from 'features/room/actions';
import { normalizeQuestions } from 'features/qa/utils/normalizeQuestions';
import {
  Answer,
  AnswerPayload,
  QAFilterCriteria,
  QAState,
  SetQuestionAnswerTypePayload,
} from 'features/qa/types';
import {
  signalingAnswerDeleted,
  signalingAnswerEdited,
  signalingQuestionAnswered,
  signalingQuestionAsked,
  signalingQuestionDeleted,
  signalingQuestionDismissed,
  signalingQuestionEdited,
  signalingQuestionLiveAnswerStarted,
  signalingQuestionLiveAnswerStopped,
  signalingQuestionReopened,
  signalingQuestionsReceived,
  signalingQuestionUpvoted,
  signalingQuestionUpvoteRemoved,
} from 'features/qa/actions';
import { toolbarPanelClosed, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { updateQuestionsState } from 'features/qa/utils/updateQuestionsState';
import { normalizeAnswer } from 'features/qa/utils/normalizeAnswer';
import { upsertQuestionFromAnswer } from 'features/qa/utils/upsertQuestionFromAnswer';

export const initialState: QAState = {
  upvoteEnabled: false,
  exportProcessing: false,
  panelOpen: false,
  unreadCount: 0,
  questions: {
    answerType: {},
    entities: {},
    allIds: [],
    editingQuestionId: null,
    editedQuestion: '',
  },
  answers: {
    entities: {},
    editingAnswerId: null,
    editedAnswer: '',
  },
};

export const qaSlice = createSlice({
  name: 'qa',
  initialState,
  reducers: {
    answerTypeSet: (state, action: PayloadAction<SetQuestionAnswerTypePayload>) => {
      const question = state.questions.entities[action.payload.questionId];
      if (action.payload.type) {
        const { index } = action.payload;
        question.typedIndex = index;
        state.questions.answerType[action.payload.questionId] = action.payload.type;
      } else {
        delete question.typedIndex;
        delete state.questions.answerType[action.payload.questionId];
      }
    },
    answerTypeReset: (state) => {
      state.questions.answerType = {};
    },
    exportQuestionsRequested: (state) => {
      state.exportProcessing = true;
    },
    exportQuestionsFulfilled: (state) => {
      state.exportProcessing = false;
    },
    exportQuestionsRejected: (state) => {
      state.exportProcessing = false;
    },
    editQuestionStarted: (state, action: PayloadAction<string>) => {
      state.questions.editingQuestionId = action.payload;
      state.questions.editedQuestion = state.questions.entities[action.payload]?.question ?? '';
    },
    editQuestionClosed: (state) => {
      state.questions.editingQuestionId = null;
      state.questions.editedQuestion = '';
    },
    editedQuestionUpdated: (state, action: PayloadAction<string>) => {
      state.questions.editedQuestion = action.payload;
    },
    editAnswerStarted: (state, action: PayloadAction<string>) => {
      state.answers.editingAnswerId = action.payload;
      state.answers.editedAnswer = state.answers.entities[action.payload]?.answer ?? '';
    },
    editAnswerClosed: (state) => {
      state.answers.editingAnswerId = null;
      state.answers.editedAnswer = '';
    },
    editedAnswerUpdated: (state, action: PayloadAction<string>) => {
      state.answers.editedAnswer = action.payload;
    },
    liveAnswerCancelled: {
      reducer: (
        state,
        action: PayloadAction<
          AnswerPayload,
          string,
          {
            cannotSeeQuestions: boolean;
          }
        >
      ) => {
        const answer = normalizeAnswer(action.payload);
        const { id: answerId } = answer;

        const question = upsertQuestionFromAnswer(state, action.payload);
        question.liveAnswerId = undefined;

        question.answers = question.answers.filter((id) => id !== answerId);
        delete state.answers.entities[answerId];

        // remove the question for roles that can't see other questions when no answers left
        if (action.meta.cannotSeeQuestions && question.answers.length === 0 && !question.local) {
          delete state.questions.entities[answer.questionId];
          state.questions.allIds = state.questions.allIds.filter((id) => id !== answer.questionId);
        }
      },
      prepare: (payload: AnswerPayload, meta: { cannotSeeQuestions: boolean }) => ({
        payload,
        meta,
      }),
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingRoomJoined, (state, action) => {
        const { questions, answers, totalCount } = normalizeQuestions(action.payload.questions);
        updateQuestionsState(state, questions, answers, totalCount);
      })
      .addCase(signalingQuestionAsked, (state, action) => {
        state.questions.entities[action.payload.id] = action.payload;
        state.questions.allIds.push(action.payload.id);

        if (!state.panelOpen) {
          state.unreadCount += 1;
        }
      })
      .addCase(signalingQuestionAnswered, (state, action) => {
        const answer = normalizeAnswer(action.payload);
        state.answers.entities[answer.id] = answer;

        const question = upsertQuestionFromAnswer(state, action.payload);
        question.answers.unshift(answer.id);
        question.status = 'answered';

        if (!state.panelOpen) {
          state.unreadCount += 1;
        }
      })
      .addCase(signalingQuestionDeleted, (state, action) => {
        const { id: questionId } = action.payload;
        const question = state.questions.entities[questionId];

        state.questions.allIds = state.questions.allIds.filter((id) => id !== questionId);

        for (const id of question.answers) {
          delete state.answers.entities[id];
        }

        delete state.questions.answerType[questionId];
        delete state.questions.entities[questionId];

        state.unreadCount = Math.max(0, state.unreadCount - 1);
      })
      .addCase(signalingAnswerDeleted, (state, action) => {
        const { id: answerId, questionId } = action.payload;
        const question = state.questions.entities[questionId];

        question.answers = question.answers.filter((id) => id !== answerId);
        delete state.answers.entities[answerId];

        state.unreadCount = Math.max(0, state.unreadCount - 1);
      })
      .addCase(signalingQuestionLiveAnswerStarted, (state, action) => {
        const answer = normalizeAnswer(action.payload);
        const { id: answerId } = answer;

        const question = upsertQuestionFromAnswer(state, action.payload);
        question.answers.unshift(answerId);
        question.liveAnswerId = answerId;

        state.answers.entities[answerId] = answer;
      })
      .addCase(signalingQuestionLiveAnswerStopped, (state, action) => {
        const answer = normalizeAnswer(action.payload);
        state.answers.entities[answer.id] = answer;

        delete state.questions.answerType[answer.questionId];

        const question = upsertQuestionFromAnswer(state, action.payload);
        question.liveAnswerId = undefined;
        question.status = 'answered';

        if (!state.panelOpen) {
          state.unreadCount += 1;
        }
      })
      .addCase(toolbarPanelOpened, (state, action) => {
        if (action.payload === 'qa') {
          state.panelOpen = true;
          state.unreadCount = 0;
        } else {
          state.panelOpen = false;
        }
      })
      .addCase(toolbarPanelClosed, (state) => {
        state.panelOpen = false;
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        const { upvoteQaEnabled } = action.payload.settings;
        state.upvoteEnabled = upvoteQaEnabled;
      })
      .addCase(signalingQuestionUpvoted, (state, action) => {
        const {
          data: { id, local },
        } = action.payload;

        const question = state.questions.entities[id];
        question.votes += 1;

        if (local) {
          question.voted = true;
        }
      })
      .addCase(signalingQuestionUpvoteRemoved, (state, action) => {
        const {
          data: { id, local },
        } = action.payload;

        const question = state.questions.entities[id];
        question.votes -= 1;

        if (local) {
          question.voted = false;
        }
      })
      .addCase(signalingAnswerEdited, (state, action) => {
        const answer = state.answers.entities[action.payload.id];

        answer.answer = action.payload.answer;
        answer.editedBy = action.payload.editedBy;
        answer.lastEditedDate = action.payload.date;
      })
      .addCase(signalingQuestionEdited, (state, action) => {
        const question = state.questions.entities[action.payload.id];

        question.question = action.payload.question;
        question.editedBy = action.payload.editedBy;
        question.lastEditedDate = action.payload.date;
      })
      .addCase(signalingQuestionDismissed, (state, action) => {
        const question = state.questions.entities[action.payload.data.id];

        question.status = 'dismissed';
      })
      .addCase(signalingQuestionReopened, (state, action) => {
        const question = state.questions.entities[action.payload.data.id];

        question.status = 'opened';
      })
      .addCase(signalingQuestionsReceived, (state, action) => {
        const { questions, answers, totalCount } = normalizeQuestions(action.payload.questions);
        updateQuestionsState(state, questions, answers, totalCount);
      });
  },
});

export const {
  answerTypeSet,
  answerTypeReset,
  exportQuestionsRequested,
  exportQuestionsRejected,
  exportQuestionsFulfilled,
  editAnswerStarted,
  editAnswerClosed,
  editedAnswerUpdated,
  editQuestionStarted,
  editQuestionClosed,
  editedQuestionUpdated,
  liveAnswerCancelled,
} = qaSlice.actions;

export const selectEditingAnswerId = (state: RootState) => state.qa.answers.editingAnswerId;
export const selectEditedAnswer = (state: RootState) => state.qa.answers.editedAnswer;
export const selectEditingQuestionId = (state: RootState) => state.qa.questions.editingQuestionId;
export const selectEditedQuestion = (state: RootState) => state.qa.questions.editedQuestion;

export const selectQuestionEntities = (state: RootState) => state.qa.questions.entities;

export const selectQuestionAnswerTypes = (state: RootState) => state.qa.questions.answerType;

export const selectQuestionAnswerType = (state: RootState, questionId: string) =>
  state.qa.questions.answerType[questionId] || null;

export const selectQuestionIds = (state: RootState) => state.qa.questions.allIds;

const selectAnswerEntities = (state: RootState) => state.qa.answers.entities;

export const selectQuestionCountsByStatus = createSelector(
  [selectQuestionEntities, selectQuestionIds],
  (entities, ids) => {
    const counts: Record<QAFilterCriteria, number> = {
      opened: 0,
      answered: 0,
      dismissed: 0,
    };

    for (const id of ids) {
      const question = entities[id];
      if (question?.status) {
        counts[question.status] += 1;
      }
    }

    return counts;
  }
);

export const selectQuestionById = (state: RootState, id: string) => state.qa.questions.entities[id];

export const selectAnswerById = (state: RootState, id: string): Answer | undefined =>
  state.qa.answers.entities[id];

export const selectAnswersByQuestion = createSelector(
  [
    selectQuestionEntities,
    selectAnswerEntities,
    (state: RootState, questionId: string) => questionId,
  ],
  (entities, answers, questionId) => {
    const question = entities[questionId];

    const result: Answer[] = [];

    for (const id of question.answers) {
      const answer = answers[id];

      // omit active live answers
      if (!answer.live || (answer.live && !answer.active)) {
        result.push(answer);
      }
    }

    return result;
  }
);

export const selectQAUnreadCount = (state: RootState) => state.qa.unreadCount;

export const selectQAExportProcessing = (state: RootState) => state.qa.exportProcessing;

export const selectQAUpvoteEnabled = (state: RootState) => state.qa.upvoteEnabled;

export default qaSlice.reducer;
