import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { sourceMatches } from 'features/streaming/utils';
import { selectExposedVideoFitment } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { SourceDetails } from 'features/layout/types';
import { useAppSelector } from 'store/hooks';

export const useStreamFitment = (source: SourceDetails) => {
  const maximizedFeed = useAppSelector(selectMaximizedStream);
  const isMaximized = sourceMatches(source, maximizedFeed);
  const appFitment = useAppSelector(selectExposedVideoFitment);
  return isMaximized ? appFitment : 'fit';
};
