import { UserId } from 'features/users/types';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { selectRoomId } from 'features/room/roomSlice';
import { call, select } from 'redux-saga/effects';
import { selectLocalUserId } from 'features/users/usersSlice';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { exchangeCreatedWhiteboardKeySaga } from 'features/e2ee/sagas/exchangeCreatedWhiteboardKeySaga';
import { waitForWhiteboardKeyDecryptionSaga } from 'features/layout/sagas/waitForTheWhiteboardKeyDecryptionSaga';
import { EventInitiator } from 'services/signaling';

export function* manageWhiteboardEncryptionSaga(id: string, initiator: EventInitiator) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (!e2eeEnabled) {
    return;
  }

  const roomId: string = yield select(selectRoomId);
  const localUserId: UserId = yield select(selectLocalUserId);

  if (localUserId === initiator.id) {
    // avoid generating a new key for an existing whiteboard
    const existingKey: AesCm256EncryptionKey | undefined = yield call(
      E2EEManager.whiteboard.getKey,
      id,
      roomId
    );

    if (!existingKey) {
      yield call(E2EEManager.whiteboard.generateKey, id, roomId);
      yield call(exchangeCreatedWhiteboardKeySaga, id);
    }
  } else {
    yield call(waitForWhiteboardKeyDecryptionSaga, id, roomId);
  }
}
