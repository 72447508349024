import { Theme, useMediaQuery } from '@mui/material';
import { selectDeviceType } from 'features/application/applicationSlice';
import { useAppSelector } from 'store/hooks';

export const useBreakpoints = () => {
  const deviceType = useAppSelector(selectDeviceType);
  const isBelowMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isShortHeight = useMediaQuery(
    (theme: Theme) => `(max-height: ${theme.breakpoints.values.sm}px)`
  );

  const isMobile = isBelowMd || (!isPortrait && isShortHeight && deviceType === 'mobile');

  return {
    isMobile,
    isPortrait,
  };
};
