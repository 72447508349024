export type RoomOnlyEvents =
  | 'userJoined'
  | 'userLeft'
  | 'streamStarted'
  | 'streamStopped'
  | 'screenshareStarted'
  | 'screenshareStopped'
  | 'externalStreamStarted'
  | 'externalStreamStopped';

export const roomOnlyEvents = [
  'userJoined',
  'userLeft',
  'streamStarted',
  'streamStopped',
  'screenshareStarted',
  'screenshareStopped',
  'externalStreamStarted',
  'externalStreamStopped',
];
