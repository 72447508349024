import { signalingWhiteboardOpened } from 'features/layout/features/content/actions';
import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { call, put } from 'redux-saga/effects';
import { board, WhiteboardData } from 'utils/whiteboard/BoardStateManager';
import { eventBus } from 'utils/eventBus';
import { parseWhiteboardStateSaga } from 'features/whiteboard/sagas/parseWhiteboardStateSaga';
import { manageWhiteboardEncryptionSaga } from 'features/whiteboard/sagas/manageWhiteboardEncryptionSaga';
import { loaderDisplayChanged } from 'features/whiteboard/whiteboardSlice';

export function* onWhiteboardOpenedSaga(action: ReturnType<typeof signalingWhiteboardOpened>) {
  yield put(loaderDisplayChanged(true));

  const {
    initiator,
    data: { id, shapes, name },
  } = action.payload;

  yield put(gridPanelOpened('whiteboard'));

  yield call(manageWhiteboardEncryptionSaga, id, initiator);

  // @TODO try to move this to board.init if we find it annoying
  const whiteboardData: WhiteboardData = {
    id,
    name,
    shapes: [],
  };
  if (shapes.length) {
    whiteboardData.shapes = yield call(parseWhiteboardStateSaga, id, shapes);
  }

  yield call(board.init, whiteboardData);

  const canEditWhiteboard: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard);

  if (canEditWhiteboard) {
    yield call(board.watch);
  } else {
    yield call(board.stop);
  }

  yield call(eventBus.sendMessage, 'whiteboardOpened', undefined, action.payload.data);

  yield put(loaderDisplayChanged(false));
}
